import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import SecondMdx from "./second.mdx";
import {HelpLink} from "../attributeeditor/help";
function _createMdxContent(props) {
  const _components = {
    code: "code",
    h1: "h1",
    p: "p",
    pre: "pre",
    table: "table",
    tbody: "tbody",
    td: "td",
    th: "th",
    thead: "thead",
    tr: "tr",
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "Dynamic Data"
    }), "\n", _jsx(_components.p, {
      children: "You can use dynamic data if you like.\r\nThis will allow you to use the data from the device in the UI."
    }), "\n", _jsxs(_components.table, {
      children: [_jsx(_components.thead, {
        children: _jsxs(_components.tr, {
          children: [_jsx(_components.th, {
            style: {
              textAlign: "left"
            },
            children: "col1"
          }), _jsx(_components.th, {
            style: {
              textAlign: "center"
            },
            children: "col2"
          }), _jsx(_components.th, {
            style: {
              textAlign: "right"
            },
            children: "col3"
          })]
        })
      }), _jsxs(_components.tbody, {
        children: [_jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            style: {
              textAlign: "left"
            },
            children: "baz"
          }), _jsx(_components.td, {
            style: {
              textAlign: "center"
            },
            children: "bim"
          }), _jsx(_components.td, {
            style: {
              textAlign: "right"
            },
            children: "bom"
          })]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            style: {
              textAlign: "left"
            },
            children: "Sample column 1"
          }), _jsx(_components.td, {
            style: {
              textAlign: "center"
            },
            children: "Sample column 2"
          }), _jsx(_components.td, {
            style: {
              textAlign: "right"
            },
            children: "Sample column 3"
          })]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            style: {
              textAlign: "left"
            },
            children: "left"
          }), _jsx(_components.td, {
            style: {
              textAlign: "center"
            },
            children: "center"
          }), _jsx(_components.td, {
            style: {
              textAlign: "right"
            },
            children: "right"
          })]
        })]
      })]
    }), "\n", _jsx(HelpLink, {
      HelpMdx: SecondMdx
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "a = a+1\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
