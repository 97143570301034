import React, {useState, useContext} from "react";

import {Select} from "@mui/material";
import {fullscreenContext} from "./fullscreenprovider";

const MySelect = ({children, ...props}) => {
    const getModalProps = useContext(fullscreenContext);

    return <Select {...props} MenuProps={getModalProps()}>
            {children}
            </Select>
    }

export default MySelect;
