import React from "react";
import {FormControl, FormControlLabel, InputLabel} from "@mui/material";

import Select from "../myselect";

const SelectAttribute = ({keyName, name, value, userData=null, onChange, children}) => {
    const handleChange = (event)  => {
        onChange(keyName, event.target.value, userData);
       }

    return <FormControl sx={{margin: 1}}>
                <InputLabel focused={true}>{name}</InputLabel>
                <Select label={name} value={value} onChange={handleChange} sx={{width: 300, padding: 1}} inputProps={{name: name}} >{children}</Select>
            </FormControl>;
    }

export default SelectAttribute;

