import React from "react";
import {Checkbox} from "@mui/material";
import Help from "./help";

import MyTooltip from "./mytooltip";

const YesNoAttribute = ({keyName, name, value, userData=null, tooltip=null, HelpMdx=null, onChange})  => {
    const handleChange = (event)  => {
        var {checked} = event.target;
        onChange(keyName, checked, userData);
        }

    var wrkVal = value;

    switch (typeof value) {
        case "string":
            wrkVal = value == "true";
            break;
        case "number":
            wrkVal = value != 0;
            break;
        }

    var content =  <>
        {name}
            <Checkbox onClick={handleChange}
                          label={name}
                          checked={wrkVal}
                          sx={{margin: 1}} />
            </>;

    if (tooltip) {
        content = <MyTooltip title={tooltip}>
                    {content}
                </MyTooltip>;
        }

    if (HelpMdx) {
        content = <>
                    {content}
                    <Help HelpMdx={HelpMdx}/>
                    </>;
        }

    return content;
    }

export default YesNoAttribute;
