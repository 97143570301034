import React, {useState} from "react";
import {Typography, MenuItem, Button, Stack, Checkbox} from "@mui/material";
import {useTranslation} from "react-i18next";

import Select from "../myselect";
import ActionButton from "./actionbutton";

import {useDesignList} from  "../designlistprovider";

const Layout = ({extensions}) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState(null);
    const [imgSrc, setImgSrc] = useState("");

    const designList = useDesignList();

    const handleChange = (event) => {
        setSelected(event.target.value);
        var selectedDesign = designList.filter((design) => design.id == event.target.value)[0];
        setImgSrc(selectedDesign.preview);
        }

    const handleEdit = () => {
        console.log("handleEdit", selected);
        }

    const handleCreate = () => {
        console.log("handleCreate", selected);
        }

    const handleDelete = () => {
        console.log("handleDelete", selected);
        }

    const handlePrintSelected = () => {
        console.log("handlePrintSelected", selected);
        }

    const handlePrintAll = () => {
        console.log("handlePrintAll", selected);
        }

    var designs = designList.filter((design) => design.type=="Design");

    var entries = designs.map((design) => {
        return <MenuItem key={design.id} value={design.id}>{design.name} - {design.description}</MenuItem>
        });

    var buttons = Object.entries(extensions?.buttons).map(([buttonId, buttonName]) => {
        const handleClick = () => {
            console.log("handleClick", buttonId);
            }
        return <ActionButton onClick={handleClick} disabled={selected==null}>{t(buttonName)}</ActionButton>
        });

    const options = Object.entries(extensions.options).map(([optionId, {name, checked}]) => {
        const handleClick = (event) => {
            console.log("handleClick", optionId, event);
            }
        return <ActionCheckbox label={t(name)} />
        });

    return <Stack direction={"column"}>
            <Typography variant={"h6"}>{t("Select Design")}</Typography>

            <Select onChange={handleChange}>
                {entries}
            </Select>

            <Stack direction={"row"}>
                <ActionButton onClick={handleEdit} disabled={selected==null}>{t("Edit Design")}</ActionButton>
                <ActionButton onClick={handleDelete} disabled={selected==null}>{t("Delete Design")}</ActionButton>
                <ActionButton onClick={handleCreate}>{t("Create Design")}</ActionButton>
            </Stack>


            <Typography variant={"h6"}>{t(extensions?.buttonsTitle)}</Typography>

            <Stack direction={"row"}>
                {buttons}
            </Stack>

            <Typography variant={"h6"}>{t(extension?.optionsTitle)}</Typography>
            {options}

            <Typography variant={"h6"}>{t("Preview (Single Label)")}</Typography>
            <img src={imgSrc} />

        </Stack>;
    }

const ActionCheckbox = ({label, ...props}) => {
    return <span>
            {label}<Checkbox label={label} {...props} sx={{margin: 1}}/>
            </span>;
    }

export default Layout;
