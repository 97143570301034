import React, {useState, useMemo, useEffect} from "react";

import {Button, Typography, Stack, MenuItem, IconButton, DialogTitle, DialogContent, DialogActions} from "@mui/material";

import MoreVertIcon from '@mui/icons-material/MoreVert';

import {useTranslation} from "react-i18next";

import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

import Dialog from "./mydialog";
import Menu from "./mymenu";

import {useDesignList} from "./designlistprovider";

import LocalizeIso from "./localizeiso";
import {useSetPath} from "./myrouter";
import {deleteResource, packageResource} from "./server";

const DesignList = ({parms}) => {
    const {t} = useTranslation();
    const setPath = useSetPath();
    const designList = useDesignList();
    const [filter, setFilter] = useState(true);

    const handleRenderAction = (cell) => {

        return <ResourceActions cell={cell} />;
        }

    const handleRenderPreview = (cell) => {
         const handleEdit = () => {
            console.log("Edit", cell.data.id);
            setPath("/editor/" + cell.data.id);
            }

        return <img onClick={handleEdit} src={cell.value} />
        }

    const handleRenderTimestamp = (cell) => {
        return <Typography> {LocalizeIso(cell.value, t("yyyy-MM-dd HH:mm:ss"))}</Typography>;
        }

    const handleToggleFilter = () => {
        setFilter((oldFilter) => !oldFilter);
        }

    const handleBack = () => {
        window.history.back();
        }

    const columnDefs = useMemo(() => {
        return [
            {field: "name", headerName: t("Name"), sortable: true},
            {field: "description", headerName: t("Description")},
            {field: "timestamp", headerName: t("Created"), sortable: true, sort: "desc", cellRenderer: handleRenderTimestamp},
            {field: "type", headerName: t("Type"), sortable: true},
            {field: "type", headerName: t("Action"), cellRenderer: handleRenderAction},
            {field: "preview", headerName: t("Preview"), cellRenderer: handleRenderPreview}
            ]
        }, [t]);

    var parentId = "";

    if (parms) {
        parentId = parms.substring(1);
        }

    if (!designList) {
        return
        }

    if (filter) {
        var rowData = designList.filter((design) => design.type == "Design" && ((design.parentDesign ?? "") == parentId));
        } else {
        var rowData = designList.filter((design) => ((design.parentDesign ?? "") == parentId));
        }
    return  <>
                <Typography variant={"h4"}>{t("Designs")}</Typography>

                <Stack direction={"row"} sx={{margin: 1}}>
                    <Button onClick={handleBack} variant={"contained"} sx={{margin: 1}}>{t("Back")}</Button>
                    <Button onClick={handleToggleFilter} variant={"contained"} color={filter ? "primary": "secondary"} sy={{margin: 1}}>{t("Designs only")}</Button>
                </Stack>
                <div className={"ag-theme-material"}>
                    <AgGridReact columnDefs={columnDefs} rowData={rowData} />
                </div>
            </>;
    }

const ResourceActions = ({cell}) => {
    const setPath = useSetPath();
    const {t} = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleEdit = () => {
        setPath("/editor/" + cell.data.id);
        }

    const handleOpen = () => {
        setPath("/designlist/" + cell.data.id);
        }

    const handleOpenMenu = (event) => {
        setMenuOpen(true);
        setAnchorEl(event.target);
        }

    const handleCloseMenu = () => {
        setMenuOpen(false);
        setAnchorEl(null);
        }

    const handlePackage = () => {
        handleCloseMenu();
        packageResource(cell.data.id);
        }

    const handleDelete = () => {
        handleCloseMenu();
        deleteResource(cell.data.id);
        }

    const handleMaster = () => {
        setPath("/master/" + cell.data.id);
        }

    var actions = [];

    if (cell.value == "Design") {
        actions.push(<Button key={"edit"} onClick={handleEdit} variant={"contained"} sx={{margin: 1}}>{t("Edit")}</Button>);
        }

    if (cell.value == "Master") {
        actions.push(<Button key={"master"} onClick={handleMaster} variant={"contained"} sx={{margin: 1}}>{t("Device")}</Button>)
        }

    if (cell.data?.isParent) {
        actions.push(<Button key={"open"} onClick={handleOpen} variant={"contained"} sx={{margin: 1}}>{t("Open")}</Button>);
        }

    actions.push(<IconButton key={"menu"} variant={"contained"} sx={{margin: 1}} onClick={handleOpenMenu}><MoreVertIcon /></IconButton>);

    return <>
            <Stack direction={"row"}>{actions}</Stack>
            <Menu open={menuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
                <MenuAction onAction={handlePackage}>{t("Package")}</MenuAction>
                <MenuAction onAction={handleDelete} onCancel={handleCloseMenu} confirmation={t("Do you really want to delete the file?")}>{t("Delete")}</MenuAction>
            </Menu>
            </>;
    }

const MenuAction = ({confirmation, onAction, onCancel, children}) => {
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const handleAction = () => {
        if (confirmation) {
            setConfirmationOpen(true);
            } else {
            onAction?.call(this);
            }
        }

    const handleConfirmation = (result) => {
        setConfirmationOpen(false);
        if (result) {
            onAction?.call(this);
            } else {
            onCancel?.call(this);
            }
        }

    return <>
            <MenuItem onClick={handleAction}>{children}</MenuItem>
            { confirmation &&
                <ConfirmationDialog open={confirmationOpen} confirmation={confirmation} onClose={handleConfirmation} />
                }
            </>;
    }

const ConfirmationDialog = ({confirmation, open, onClose}) => {
    const {t} = useTranslation();
    return <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t("Confirmation")}</DialogTitle>
                <DialogContent>{confirmation}</DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={() => onClose(false)}>{t("No")}</Button>
                    <Button variant={"contained"} onClick={() => onClose(true)}>{t("Yes")}</Button>
                </DialogActions>
            </Dialog>;
    }


export default DesignList;
