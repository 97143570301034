import React, {useState, useContext} from "react";

import {Menu} from "@mui/material";
import {fullscreenContext} from "./fullscreenprovider";

const MyMenu = ({children, ...props}) => {
    const getModalProps = useContext(fullscreenContext);

    return <Menu {...props} {...getModalProps()}>
            {children}
            </Menu>
    }

export default MyMenu;
