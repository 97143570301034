import React, {useState, useContext} from "react";

import {Drawer} from "@mui/material";
import {fullscreenContext} from "./fullscreenprovider";

const MyDrawer = ({children, ...props}) => {
    const getModalProps = useContext(fullscreenContext);

    return <Drawer {...props} {...getModalProps()}>
            {children}
            </Drawer>
    }

export default MyDrawer;
