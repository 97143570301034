import React, {useState, createContext, useContext, useEffect} from "react";
import {atom, useAtom, useAtomValue, useSetAtom} from "jotai";

import {getDesignList, getFontList} from "./server";
import {useUserInfo} from "./userinfoprovider";
import SseSource from "./ssesource";

const designListAtom = atom([]);
const designListRefreshAtom = atom(null);

export const DesignListProvider = ({children}) => {
    const setDesignList = useSetAtom(designListAtom);
    const setDesignListRefresh = useSetAtom(designListRefreshAtom);
    const [fontList, setFontList] = useState([]);
    const [channel, setChannel] = useState(null);

    const userInfo = useUserInfo();

    const handleRefresh = async () => {
        var data = await getDesignList();
        setDesignList([...data.designs, ...fontList]);
        setChannel(data.channel);
        }

    const handleData = (data) => {
        console.log("handleData", data);
        setDesignList((oldList) => {
            var newList = [...oldList];
            var index = newList.findIndex((item) => item.id == data.id);
            if (index >= 0) {
                if (data.status == "Deleted") {
                    newList = newList.filter((item) => item.id != data.id);
                    } else {
                    newList[index] = data;
                    }
                }   else {
                newList.push(data);
                }
            return newList;
            });
        }

    useEffect(() => {
        setDesignListRefresh(handleRefresh);
        }, []);

    useEffect(() => {
        let valid = true;

        const fetchData = async () => {

            var data = await getDesignList();
            if (valid) {
                setDesignList([...data.designs, ...fontList]);
                setChannel(data.channel);
                }
            }

        fetchData();

        return () => {
            valid = false;
            }
        }, [userInfo, fontList]);

    useEffect(() => {
        let valid = true;

        const fetchData = async () => {

            var data = await getFontList();

            if (valid) {
                var fonts = data.fontList.map((font) => {
                    return {
                        id: font,
                        name: font,
                        description: "",
                        type: "Font",
                        blob: font,
                        timestamp: "2020-01-01"
                        }
                    });
                setFontList(fonts);
                }
            }

        fetchData();

        return () => {
            valid = false;
            }
        }, []);

    return <>
            <SseSource channel={channel} onData={handleData} />
            {children}
            </>;
    }

export const useDesignList = () => {
    return useAtomValue(designListAtom);
    }

export const useDesignListRefresh = () => {
    return useAtomValue(designListRefreshAtom);
    }

export default DesignListProvider;
