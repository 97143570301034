import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import TextAttribute from "./textattribute";

const NumberAttribute = ({value, keyName,  name, min, max, required, width=300, variant="form",
                             readOnly=false, style="", userData=null, onChange}) => {
    const {t} = useTranslation();

    const [curValue, setCurValue] = useState(value);
    const [valid, setValid] = useState(true);
    const [error, setError] = useState("");

    const isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
        }

    const  handleBlur = (event) => {
        if (valid) {
            onChange(keyName, value, userData);
            }
        }

    const handleChange = (keyName, value) => {
        setCurValue(value);

        if (required) {
            if (value == "") {
                setValid(false);
                setError(t("Required"));
                return;
                }
            }
        if (!isNumber(value)) {
            setValid(false);
            setError(t("Not a number"));
            return;
            }

        var val = Number(value);
        if (min != undefined && val < min) {
            setValid(false);
            setError(t("Too small, minimum is {{min}}", {min: min}));
            return;
            }

        if (max != undefined && val > max) {
            setValid(false);
            setError(t("Too large, maximum is {{max}}", {max: max}));
            return;
            }

        setValid(true);
        setError("");
        onChange(keyName, value, userData);
        }

    useEffect(() => {
        if (value != curValue) {
            setCurValue(value);
            setError("");
            setValid(true);
            }
        }, [value]);

    return <TextAttribute label={name} name={name} keyName={keyName} value={curValue} valid={valid} error={error} width={width} variant={variant} readOnly={readOnly} style={style} userData={userData} onBlur={handleBlur} onChange={handleChange}  />;
    }

export default NumberAttribute;
