import React, {useState} from "react";
import {Button, Typography, Stack} from "@mui/material";

import {useTranslation} from "react-i18next";

import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

if (!navigator.pdfViewerEnabled) {
    const origin = document.location.origin;
    pdfjs.GlobalWorkerOptions.workerSrc = new URL('/pdf.worker.min.js', origin).toString();
    }


const PdfViewer = ({url, withControl=true}) => {
    if (navigator.pdfViewerEnabled) {
        return <iframe src={url}  style={{width: "100%", height: "90vh", border: "none", padding: 0}}/>;
        }

    return <JsPdfViewer url={url} withControl={withControl} />;
    }

const JsPdfViewer = ({url, withControl=true}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const {t} = useTranslation();

    const handleLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
        setPageNumber(1);
        }

    return <>
                <Stack direction={"column"}>
                { withControl &&
                    <Stack direction={"row"} style={{margin: 1}}>
                        <Button variant={"contained"} sx={{margin: 1}} onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1}>{t("Prev")}</Button>
                        <Button variant={"contained"} sx={{margin: 1}} onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber >= numPages}>{t("Next")}</Button>
                        {numPages &&
                            <>
                                <Typography>
                                    {t("Page {{pageNumber}} of {{numPages}}", {pageNumber: pageNumber, numPages: numPages})}
                                </Typography>

                                <Button variant={"contained"} href={url} sx={{margin: 1}}>{t("Download")}</Button>
                            </>
                            }

                    </Stack>
                    }
                    <div>

                        {url &&
                            <Document file={url} onLoadSuccess={handleLoadSuccess}>
                                <Page pageNumber={pageNumber}/>
                            </Document>
                            }
                    </div>
                </Stack>
            </>;
    }

export const SamplePdfViewer = () => {
    var url = new URL("/sample.pdf", import.meta.url).toString();

    return <PdfViewer url={url} />
    }

export default PdfViewer;
