import React, {useRef, useState} from "react";

import {DialogContent, DialogTitle, MenuItem, Select} from "@mui/material";
import DataArrayIcon from "@mui/icons-material/DataArray";
import EastIcon from "@mui/icons-material/East";
import SouthIcon from "@mui/icons-material/South";
import WestIcon from "@mui/icons-material/West";
import NorthIcon from "@mui/icons-material/North";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import LockIcon from "@mui/icons-material/Lock";


import {useTranslation} from "react-i18next";

import {BarcodeMap} from "../layouteditor/barfield";
import ToolbarEntry, {ToolbarSelection} from "./toolbarentry";
import ColorAttribute from "./colorattribute";
import NumberAttribute from "./numberattribute";
import ResourceAttribute from "./resourceattribute";
import MyTooltip from "./mytooltip";
import Dialog from "../mydialog";
import DataAttribute from "./dataattribute";

const directionMap = new Map([["0", EastIcon], ["270", SouthIcon], ["180", WestIcon], ["90", NorthIcon]]);
const hAlignMap = new Map([["W", FormatAlignLeftIcon], ["C", FormatAlignCenterIcon], ["E", FormatAlignRightIcon]]);
const vAlignMap = new Map([["N", VerticalAlignTopIcon], ["Z", VerticalAlignCenterIcon], ["S", VerticalAlignBottomIcon]]);
const hAlign = {"NW": "W", "N": "C", "NE": "E", "W": "W", "Z": "C", "E": "E", "SW": "W" , "S": "C", "SE": "E"};
const vAlign = {"NW": "N", "N": "N", "NE": "N", "W": "Z", "Z": "Z", "E": "Z", "SW": "S" , "S": "S", "SE": "S"};

const aspectRatioMap= new Map([["NO", FullscreenIcon], ["YES", AspectRatioIcon], ["KEEP", LockIcon]]);

const alignTransform = {
        "NW": {W: "NW", C: "N", E: "NE", N: "NW", Z: "W", S: "SW"},
        "N": {W: "NW", C: "N", E: "NE", N: "N", Z: "Z", S: "S"},
        "NE": {W: "NW", C: "N", E: "NE", N: "NE", Z: "E", S: "SE"},
        "W": {W: "W", C: "Z", E: "E", N: "NW", Z: "W", S: "SW"},
        "Z": {W: "W", C: "Z", E: "E", N: "N", Z: "Z", S: "S"},
        "E": {W: "W", C: "Z", E: "E", N: "NE", Z: "E", S: "SE"},
        "SW": {W: "SW", C: "S", E: "SE", N: "NW", Z: "W", S: "SW"},
        "S": {W: "SW", C: "S", E: "SE", N: "N", Z: "Z", S: "S"},
        "SE": {W: "SW", C: "S", E: "SE", N: "NE", Z: "E", S: "SE"}
        };

const ToolbarPositioning = ({record, onFormChange}) => {

    const {t} = useTranslation();
    const [dataOpen, setDataOpen] = useState(false);
    const dataRef = useRef(null);

    const handleFormAlignChange = (key, value) => {
        const newValue = alignTransform[record.form.align][value];

        onFormChange("align", newValue);
        }

    const handleFormValueChange = (key, value) => {
        var oldValue = record.form[key];
        var newValue = structuredClone(oldValue);
        newValue.value = value;
        onFormChange(key, newValue);
        }

    const handleSelectChange = (event) => {

        //console.log("handleSelectChange", event);
        var newValue = event.target.value;
        onFormChange("barcodeType", newValue);
        }

    const handleData = () => {
        setDataOpen(true);
        }

    const handleDataClose = () => {
        setDataOpen(false);
        }

    var recordType = record.type;

    var bitmapData = record.form.bitmap || {value: ""};

    if (recordType == "bar") {
        var barcodeEntries = Object.entries(BarcodeMap);
        var barcodeOptions = barcodeEntries.map(([key, value], index) => {
            return <MenuItem key={key} value={key}>{key}</MenuItem>;
            });
        }

    var resourceType = null;
    var dataName = t("Data");
    var dataKey = "data";

    if (recordType == "bitmap") {
        resourceType = "Graphic";
        dataName = t("Bitmap");
        dataKey = "bitmap";
        }

    if (recordType == "sublabel") {
        resourceType = "Design";
        dataName = t("Sublabel");
        dataKey = "sublabel";
        }

    return <>
        {!["box", "line"].includes(recordType) &&
            <ToolbarSelection name={t("Direction")} value={record.form.direction} iconMapping={directionMap}
                              keyName={"direction"} onChange={onFormChange}/>
            }
        {!["box", "line"].includes(recordType) &&
            <>
                <ToolbarSelection name={t("Align")} value={hAlign[record.form.align]} iconMapping={hAlignMap}
                                  keyName={"align"} onChange={handleFormAlignChange}/>
                <ToolbarSelection name={t("Vertical Align")} value={vAlign[record.form.align]} iconMapping={vAlignMap}
                                  keyName={"align"} onChange={handleFormAlignChange}/>
            </>}

        {!["bitmap", "bitmapGrid"].includes(recordType) &&
            <ColorAttribute name={t("Color")} value={record.form.color} keyName={"color"} variant={"button"}
                            onChange={onFormChange}/>
            }

        {["box", "line"].includes(recordType) &&
            <NumberAttribute value={record.form.stroke} keyName={"stroke"} name={t("Stroke")} variant={"toolbar"}
                             min={0} max={10} width={"3em"} onChange={onFormChange}/>
            }

        {["field", "multi", "price"].includes(recordType) &&
            <>
                <ResourceAttribute value={record.form.font} keyName={"font"} name={t("Font")} resourceType={"Font"}
                                   variant={"toolbar"} withUpload={false} onChange={onFormChange}/>
                <NumberAttribute value={record.form.fontSize} keyName={"fontSize"} name={t("Font Size")}
                                 variant={"toolbar"} min={1} max={100} width={"3em"} onChange={onFormChange}/>
                <NumberAttribute value={record.form.minFontSize} keyName={"minFontSize"} name={t("Min Font Size")}
                                 variant={"toolbar"} min={0} width={"3em"} max={100} onChange={onFormChange}/>
            </>}

        {record.type == "bar" &&
            <MyTooltip title={t("Barcode")}>
                <Select value={record.form.barcodeType} onChange={handleSelectChange}
                        sx={{width: "20em"}}>{barcodeOptions}</Select>
            </MyTooltip>
           }

        {recordType == "bitmap" &&
            <>
                <ResourceAttribute value={bitmapData.value} keyName={"bitmap"} name={t("Bitmap")}
                                   resourceType={"Graphic"} variant={"toolbar"} onChange={handleFormValueChange}/>
                <ToolbarSelection value={record.form?.keepRatio ?? "YES"} keyName={"keepRatio"} name={t("Keep Ratio")}
                                  iconMapping={aspectRatioMap} onChange={onFormChange}/>
            </>}

        {["bar", "bitmap", "bitmapGrid", "field", "input", "multi", "price", "sublabel"].includes(recordType) &&
            <>
                <ToolbarEntry ref={dataRef} name={t("Data")} iconTrue={DataArrayIcon} onChange={handleData}/>
                <Dialog open={dataOpen} onClose={handleDataClose} maxWidth={"lg"}>
                    <DialogTitle>{t("Data")}</DialogTitle>
                    <DialogContent>
                        <DataAttribute name={dataName} value={record.form[dataKey]} keyName={dataKey}
                                       resourceType={resourceType} onChange={onFormChange}/>
                    </DialogContent>

                </Dialog>
            </>
        }

    </>;

    }

export default ToolbarPositioning;
