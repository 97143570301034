import React, {useState, createContext, useContext, useEffect} from "react";
import {ListItem, ListItemButton} from "@mui/material";

import {TestSignal} from "./signals";
import ErrorBoundary from "./errorboundary";

import {hoverMenu} from "./styles";

import {atom, useAtom, useAtomValue, useSetAtom} from "jotai";

const pathAtom = atom("/");

const primePathAtom = atom ((get) => {
    const path = get(pathAtom);
    if (path) {
        const remotePath = path.split("#");
        return "/" + remotePath[0].split("/")[1];
        }
    return "/";
    });

const restPathAtom = atom ((get) => {
    const path = get(pathAtom);
    if (path) {
        const remotePath = path.split("#");
        return "/" + remotePath[0].split("/").slice(2).join("/");
       }
    return "/";
    });

export const MyRouter = ({initialPath,
                             onSelect=null, children}) => {

    const [path, setPath] = useAtom(pathAtom);

    const handlePopState = (event) => {
         setPath(event.state);
        }

    useEffect(() => {
        setPath(initialPath);

        window.addEventListener("popstate", handlePopState);
        return () => {
            window.removeEventListener("popstate", handlePopState);
            }
        }, []);

    useEffect(() => {
        window.history.pushState(path, "");
        onSelect?.call( this, path);
        TestSignal.value = path;
        }, [path]);

    return <ErrorBoundary>
            {children}
            </ErrorBoundary>;
    }

export const MyRouterMenuItem = ({label, path}) => {

    const setPath = useSetAtom(pathAtom);

    const handleClick = () => {
        setPath(path);
        }

    return <MyMenuItem onClick={handleClick} label={label}/>
    }

export const MyMenuItem = ({label, onClick}) => {
    return <ListItem>
                <ListItemButton sx={hoverMenu} onClick={onClick}>{label}</ListItemButton>
            </ListItem>;
    }

export const MyRoute = ({path, Component}) => {
    const primePath  = useAtomValue(primePathAtom);
    const restPath = useAtomValue(restPathAtom);

    if (path == primePath) {
        return <ErrorBoundary>
                <Component parms={restPath} />
                </ErrorBoundary>;
        }
    }

export const useSetPath = () => {
    return useSetAtom(pathAtom);
    }

