
export const OldToNew = (labelDescription) => {

    var convertedLabel = structuredClone(labelDescription).map((value, index) => {
        /**
        if (value.x == undefined) {
            value.rotation = (-value.form.angle) || 0;
            if (value.rotation < 0) {
                value.rotation = 360 + value.rotation;
                }

            if (value.rotation == 0) {
                value.x = value.position.left;
                value.y = value.position.top;
                value.width = value.position.width;
                value.height = value.position.height;
                } else if (value.rotation == 90) {
                value.x = value.position.left + value.position.width;
                value.y = value.position.top;
                value.width = value.position.height;
                value.height = value.position.width;

                } else if (value.rotation == 180) {
                value.x = value.position.left + value.position.width;
                value.y = value.position.top + value.position.height;
                value.width = value.position.width;
                value.height = value.position.height;
                } else if (value.rotation == 270) {
                value.x = value.position.left;
                value.y = value.position.top + value.position.height;
                value.width = value.position.height;
                value.height = value.position.width;
                }

            delete value.position;
        }
        **/

        if (value?.form?.label_version_created != "2.00.0000") {

            var entries = Object.entries(value?.form);

            entries.map(([key2, value2]) => {
                if (["markupUsed", "underline", "humanReadable"].indexOf(key2) > -1) {
                    value.form[key2] = (value2 == "YES");
                    }

                if (key2 == "label_version_created") {
                    value.form[key2] = "2.00.0000";
                    }

                if (typeof (value2) == "object") {

                    var subEntries = Object.entries(value2);
                    var newSubEntries = subEntries.map(([key3, value3]) => {
                        if (key3 == "type") {
                            value.form[key2].dynamic = (value3 == "dynamic");
                            }
                        if (["dataMapping", "dataScriptSelect"].indexOf(key3) > -1) {
                            value.form[key2][key3] = (value3 == "YES");
                            }
                        if (["mapping", "textmapping"].indexOf(key3) > -1) {
                            value.form[key2][key3] = Object.entries(value3);
                            }
                        });
                    }
                });
            }
        return value;
        });

    return convertedLabel;
    }

export const snapPosition = (position, snap) => {

    if (snap == 0) {
        return position;
        }

    var {left, top, width, height} = position;
    left += snap / 2;
    top += snap / 2;
    width += snap / 2;
    height += snap / 2;


    left -= left % snap;
    top -= top % snap;
    width -= width % snap;
    height -= height % snap;

    return {left, top, width, height};
    }

export const extractPosition = (field, snap) => {

    var {position, form: {direction= "0"}} = field;
    var x = 0
    var y= 0
    var Width = 0;
    var Height = 0;

    var {left, top, width, height} = snapPosition(position, snap);

    var rotation = (-parseInt(direction)) ?? 0;
    if (rotation < 0) {
        rotation += 360;
        }

    if (rotation == 0) {
        x = left;
        y = top;
        Width = width;
        Height = height;
        } else if (rotation == 90) {
        x = left + width;
        y = top;
        Width = height;
        Height = width;

        } else if (rotation == 180) {
        x = left + width;
        y = top + height;
        Width = width;
        Height = height;
        } else if (rotation == 270) {
        x = left;
        y = top + height;
        Width = height;
        Height = width;
        }

    var ret = {x, y, width: Width, height: Height, rotation};

    //console.log("extractPosition", field, ret);
    return ret;
    }
export const encodePosition = (position, snap) => {
    var {x, y, width, height, rotation} = position;
    var left = 0;
    var top = 0;
    var Width = 0; ;
    var Height = 0;
    var angle = 0;

    if (rotation == 0) {
        left = x;
        top = y;
        Width = width;
        Height = height;
        } else if (rotation == 90) {
        left = x - height;
        top = y;
        Width = height;
        Height = width;
        } else if (rotation == 180) {
        left = x - width;
        top = y - height;
        Width = width;
        Height = height;
        } else if (rotation == 270) {
        left = x;
        top = y - width;
        Width = height;
        Height = width;
        }

    var direction = ((-rotation + 360) % 360).toString();

    top = Math.max(0, top);
    left = Math.max(0, left);
    Width = Math.max(0, Width);
    Height = Math.max(0, Height);
    Width = Math.min(100-left, Width);
    Height = Math.min(100-top, Height);

    var position = {top, left, width: Width, height: Height};
    position = snapPosition(position, snap);

    var ret = {position, form: {direction}};
    //console.log("encodePosition", position, ret);
    return ret;
    }

export const NewToOld = (labelDescription) => {

    var convertedLabelDescription = structuredClone(labelDescription).map((value, index) => {

        /**
        value["position"] = {};

        if (value.rotation == 0) {
            value.position.left = value.x;
            value.position.top = value.y;
            value.position.width = value.width;
            value.position.height = value.height;
            } else if (value.rotation == 90) {
            value.position.left = value.x - value.height;
            value.position.top = value.y;
            value.position.width = value.height;
            value.position.height = value.width;
            } else if (value.rotation == 180) {
            value.position.left = value.x - value.width;
            value.position.top = value.y - value.height;
            value.position.width = value.width;
            value.position.height = value.height;
            } else if (value.rotation == 270) {
            value.position.left = value.x;
            value.position.top = value.y - value.width;
            value.position.width = value.height;
            value.position.height = value.width;
            }
        value.form.angle = -value.rotation + 360;

        delete value.x;
        delete value.y;
        delete value.width;
        delete value.height;
        delete value.rotation;

        **/

        var entries = Object.entries(value.form);

        entries.map(([key2, value2]) => {
            try {
                if (["markupUsed", "underline", "humanReadable"].indexOf(key2) > -1) {
                    if (value.form[key2]) {
                        value.form[key2] = "YES";
                        } else {
                        value.form[key2] = "NO";
                        }
                    }

                if (key2 == "label_version_created") {
                    value.form[key2] = "1.00.0000";
                    }

                if (typeof (value2) == "object") {
                    var subEntries = Object.entries(value2);
                    var newSubEntries = subEntries.map(([key3, value3]) => {
                        if (key3 == "dynamic") {
                            if (value.form[key2][key3]) {
                                value.form[key2]["type"] = "dynamic";
                                } else {
                                value.form[key2]["type"] = "static";
                                }
                            delete value.form[key2][key3];
                            }


                        if (["dataMapping", "dataScriptSelect"].indexOf(key3) > -1) {
                            if (value.form[key2][key3]) {
                                value.form[key2][key3] = "YES";
                                } else {
                                value.form[key2][key3] = "NO";
                                }
                            }
                        if (["mapping", "textmapping"].indexOf(key3) > -1) {
                            //value.form[key2][key3] = Object.fromEntries(value3);
                            }
                        });
                    }

                } catch (e) {

                }});

        return value;

        });
    return convertedLabelDescription;
    }
