import React, {useState, useRef} from "react";

import {Button, DialogActions, DialogContent, DialogTitle, Grid, Tab, Paper, MenuItem} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";

import {useTranslation} from "react-i18next";

import Dialog from "./mydialog";

import Dform from "./attributeeditor/dform";
import {getLabelUrl, submitJob} from "./server";
import SelectAttribute from "./attributeeditor/selectattribute";
import {useSetPath} from "./myrouter";
import PdfViewer from "./pdfviewer";
import {requirePrompt, requireNoXpathAndPrompt} from "./formextension";

export const FormEditor = ({vform, oauth, onClose}) => {

    const {t} = useTranslation();

    const [selected, setSelected] = useState("parameters");
    const [src, setSrc] = useState(null);
    const [curRecord, setCurRecord] = useState({});
    const [curSource, setCurSource] = useState("manual");
    const [curFile, setCurFile] = useState(null);
    const inputRef = useRef();
    const setPath = useSetPath();

    const  handleChange = (event, newValue) => {
        setSelected(newValue);
        }

    const handleDformChange = (key, newValue) => {

        if (newValue != curRecord) {
            setCurRecord(newValue);
            }
        }

    const handleSelectFile = () => {
        inputRef.current.click();
        }

    const handleSubmit = () => {
        submitJob(curRecord.id, null, curRecord, oauth);
        onClose();
        }

    const handleCreate = () => {
        switch (curSource) {
            case "manual":
                var newSrc = getLabelUrl(curRecord);
                console.log(curRecord, newSrc);
                setSrc(newSrc);
                setSelected("pdf")
                break;

            case "sample":
                submitJob(curRecord.id, null, curRecord, oauth);
                onClose();
                setPath("/joblist");
                break;

            case "file":
                submitJob(curRecord.id, curFile, curRecord, oauth);
                onClose();
                setPath("/joblist");
                break;

            default:
                break;
            }
        }

    const handleSource = (key, newValue) => {
        setCurSource(newValue);
        }

    const handleFileChange = (event) => {
        var file = event.target.files[0];
        setCurFile(file);
        }

    return <>
            <TabContext value={selected}>
            <TabList onChange={handleChange}>
                <Tab label={t("Parameters")} value={"parameters"}/>
                <Tab label={t("PDF")} value={"pdf"}/>
                <Tab label={t("PNG")} value={"png"}/>
            </TabList>
            <TabPanel value={"parameters"}>

                <SelectAttribute name={t("Data Source")} keyName={"source"} value={curSource} onChange={handleSource}>
                    <MenuItem value={"sample"}>{t("Sample")}</MenuItem>
                    <MenuItem value={"file"}>{t("File")}</MenuItem>
                    <MenuItem value={"manual"}>{t("Manual")}</MenuItem>
                </SelectAttribute>
                {curSource == "manual" &&
                    <Dform rules={vform} value={curRecord} preProc={requirePrompt} onChange={handleDformChange} />
                    }

                {curSource != "manual" &&
                     <Dform rules={vform} value={curRecord} preProc={requireNoXpathAndPrompt} onChange={handleDformChange} />
                    }

                {curSource == "file" &&
                    <>
                        <Button variant={"contained"} onClick={handleSelectFile} sx={{margin: 2}}>{t("Select File")}</Button>
                        <input ref={inputRef} type="file" onChange={handleFileChange} hidden accept={".json,.csv,.xlsx,.xls,.xml,.odf"}/>
                        </>
                    }

                 <div sx={{margin: 2}}>
                 <Button variant={"contained"} sx={{margin: 2}}onClick={handleCreate}>{t("Create")}</Button>
                 </div>
            </TabPanel>
            <TabPanel value={"pdf"} >
                {navigator.pdfViewerEnabled &&
                    <iframe src={src}  style={{width: "80vw", height:"70vh", border: 0, padding: 0}}/>
                    }
                {!navigator.pdfViewerEnabled &&
                    <PdfViewer url={src}  withControl={false}/>
                    }
            </TabPanel>

            <TabPanel value={"png"} >
                <iframe src={src}  style={{width: "80vw", height:"70vh", border: 0, padding: 0}}/>
            </TabPanel>
            </TabContext>
            </>;
    }

const FormEditorDialog = ({open, onClose, ...props}) => {

    const {t} = useTranslation();

    return <Dialog open={open} maxWidth={"xxl"}>
             <DialogContent >
                <DialogTitle>{t("Produce Label")}</DialogTitle>
                <FormEditor onClose={onClose} {...props} />

                <DialogActions>
                    <Button variant={"contained"} onClick={onClose}>{t("Cancel")}</Button>
                </DialogActions>
            </DialogContent>
          </Dialog>;
    }

export default FormEditorDialog;
