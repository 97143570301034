import React, {useState, useRef, forwardRef, useImperativeHandle, useLayoutEffect} from "react";
import {Layer, Stage, Transformer, Group, Rect, Image} from "react-konva";
import useImage from "use-image";

import Field from "./field";
import {extractPosition, encodePosition} from "../conversion";
import {getResourceUrl} from "../server";

const LayoutEditor = ({selected, depSelected, label, onUpdate, onDelete, onBack, onSelect, forwardedRef}) => {

    const transformerRef = useRef();
    const outerTransformerRef = useRef();
    const containerRef = useRef();
    const stageRef = useRef();
    const imageRef = useRef();
    const viewportRef = useRef();
    const innerRef = useRef();
    const [selectedShapes, setSelectedShapes] = useState([]);
    const [curWidth, setCurWidth] = useState(0);
    const [curHeight, setCurHeight] = useState(0);
    const [dragging, setDragging] = useState(false);
    const [outerSelected, setOuterSelected] = useState(false);
    const [transformerActive, setTransformerActive] = useState(false);

    var snap = 0;

    try {
        snap = Number(label[0]?.form?.label_snap ?? "0") / 100;
    } catch (e) {}


    useImperativeHandle(forwardedRef, () => {
            return {
                onEndSelection: function onEndSelection(event) {
                    transformerRef.current.nodes([]);
                    setSelectedShapes([]);
                },
                onSelect: function onSelect(fieldId, shift) {
                    transformerRef.current.nodes([]);
                    setSelectedShapes([]);
                    handleSelect(fieldId, shift);
                }
            }
        }
    );

    const handleDragStart = (event) => {
        setDragging(true);
        }

    const handleDragStartInner = () => {
        console.log("Drag Start Inner");
        }

    const handleDragEnd = (event) => {

        console.log("handleDragEnd", event);

        event.cancelBubble = true;

        var index = event.target.index;

        var newLabel = structuredClone(label);

        var ids = [selected, ...depSelected];

        var nodes = transformerRef.current.nodes();

        nodes.forEach((node, index) => {

            var position = {
                x: node.x(),
                y: node.y(),
                width: node.width(),
                height: node.height(),
                rotation: node.rotation()
                };

            console.log("dragEnd", position);

            var {position, form} = encodePosition(position, snap);


            console.log("dragEnd", position);
            var id = ids[index];
            newLabel[id].position = position;

            if (newLabel[id].form == undefined) {
                newLabel[id].form = {direction: form.direction};
                }   else {
                newLabel[id].form.direction = form.direction;
                }
            });
        console.log(newLabel);
        onUpdate(newLabel);
        setDragging(false);
        }

    const handleSelect = (fieldId, shift, node) => {
        outerTransformerRef.current.nodes([]);

        onSelect(fieldId, shift);

        if (node) {
            setSelectedShapes([node.current]);
            var oldNodes = [];
            if (shift) {
                oldNodes = transformerRef.current.nodes();
                }
            const newNodes = oldNodes.concat([node.current]);
            transformerRef.current.nodes(newNodes);
            setTransformerActive(true);
            //transformerRef.current.nodes([node.current]);
            } else {
            setSelectedShapes([]);
            transformerRef.current.nodes([]);
            setTransformerActive(false);
            }
        transformerRef.current.getLayer().batchDraw();
        }

    const handleUnselect = (event) => {

        if (event.target == event.target.getStage()) {
            if (!event.evt.shiftKey) {
                doUnselect();
                transformerRef.current.nodes([]);
                setSelectedShapes([]);
                handleSelect(null, false, null);
            }
        }
    }

    const doUnselect = () => {
        transformerRef.current.nodes([]);
        setSelectedShapes([]);
        setTransformerActive(false);
        handleSelect(null, false, null);
        }

    const handleTransformEnd = ( event) => {
        console.log("handleTransformEnd", event);

        event.cancelBubble = true;

        var newLabel = structuredClone(label);

        var ids = [selected, ...depSelected];

        var nodes = transformerRef.current.nodes();

        nodes.forEach((node, index) => {

            var scaleX = node.scaleX();
            var scaleY = node.scaleY();

            var newWidth = node.width() * node.scaleX();
            var newHeight = node.height() * node.scaleY();

            node.width(newWidth);
            node.height(newHeight);
            node.scaleX(1);
            node.scaleY(1);

            var position = {
                x: node.x(),
                y: node.y(),
                width: newWidth,
                height: newHeight,
                rotation: node.rotation()
                };

            var {position, form} = encodePosition(position, snap);
            var id = ids[index];
            newLabel[id].position = position;


            if (newLabel[id].form == undefined) {
                newLabel[id].form = {direction: form.direction};
                } else {
                newLabel[id].form.direction = form.direction;
                }
            });

        onUpdate(newLabel);

        transformerRef.current.nodes([]);
        transformerRef.current.getLayer().batchDraw();
        window.setTimeout(() => {
            setTransformerActive(true);
            transformerRef.current.nodes(selectedShapes);
            transformerRef.current.getLayer().batchDraw();
        }, 200);

    }

    const handleOuterTransformEnd = (event) => {
        console.log("handleOuterTransformEnd", event);
        if (selected != null) {
            event.preventDefault = true;
            return
            }

        event.cancelBubble = true;

        var newLabel = structuredClone(label);

        var node = viewportRef.current;

        var viewport = extractPosition(label[0], snap);


        var scaleX = node.scaleX()
        var scaleY = node.scaleY()

        //node.scaleX(1);
        //node.scaleY(1);

        var newWidth = node.width() * scaleX / viewport.width * 100;
        var newHeight = node.height()  * scaleY / viewport.height * 100;

        var position = {
            x: node.x(),
            y: node.y(),
            width: newWidth,
            height: newHeight,
            rotation: node.rotation(),
            scaleX,
            scaleY
            };

        node.scaleX(100/newWidth);
        node.scaleY(100/newHeight);

        console.log(position)

        var {position, form} = encodePosition(position, snap);

        console.log(position)
        newLabel[0].position = position;

        onUpdate(newLabel);

        outerTransformerRef.current.nodes([]);
        outerTransformerRef.current.getLayer().batchDraw();
        setOuterSelected(false);

        window.setTimeout(() => {
            outerTransformerRef.current.nodes([]);
            outerTransformerRef.current.getLayer().batchDraw();
            }, 200);


        }

    const handleOuterDragStart = (event) => {
        console.log("handleOuterDragStart", event);

        event.cancelBubble = true;
        if (selected != null) {
            event.preventDefault = true;
            return
            }
        }

    const handleOuterDragEnd = (event) => {
        if (selected != null) {
            event.preventDefault = true;
            return
            }

        event.cancelBubble = true;

        var newLabel = structuredClone(label);


        var node = viewportRef.current;

        var position = {
            x: node.x(),
            y: node.y(),
            width: node.width(),
            height: node.height(),
            rotation: node.rotation()
            };

        console.log(position);

        var {position, form} = encodePosition(position, snap);

        console.log(position)

        newLabel[0].position = position;


        onUpdate(newLabel);
    }

    const handleOuterSelect = (event) => {
        console.log("handleOuterSelect", event);
        transformerRef.current.nodes([]);
        setSelectedShapes([]);
        onSelect(null, false, null)


        if (outerTransformerRef.current.nodes().length < 1) {
            doUnselect();
            outerTransformerRef.current.nodes([viewportRef.current]);
            } else {
            outerTransformerRef.current.nodes([]);
            outerTransformerRef.current.getLayer().batchDraw();

            }
        setOuterSelected(true);
        }

    const handleDelete = (event) => {
        endSelection();
        onDelete(event);
        }

    const handleBack = (event) => {
        endSelection();
        onBack(event);
        }

    const handleUpdate = (newText) => {
        //console.log(selected, newText);
        var newLabel = structuredClone(label);
        if (newLabel[selected].form.data.sample) {
            newLabel[selected].form.data.sample = newText;
            } else {
            newLabel[selected].form.data.value = newText;
            }
        onUpdate(newLabel);
        }

    const handleResize = (event) => {
        var container = containerRef.current;
        var width = container.offsetWidth;
        var height = container.offsetHeight;

        if (width != curWidth || height != curHeight) {

            setCurWidth(width);
            setCurHeight(height);
            }
        }

    const handleDragBoundLabel = (newPos,  event) => {
        console.log("handleDragBoundLabel", newPos, event);
        return newPos;
        }

    const handleDragBoundInner = (newPos,  event) => {
        console.log("anchorDragBound", newPos, event);
        return newPos;
        }

    useLayoutEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    useLayoutEffect(() => {
        handleResize();
    }, [containerRef]);

    const [img] = useImage(getResourceUrl(label[0]?.form?.label_background ?? ""));

    if (label[0]?.form?.label_page_height == undefined) {
        return <div ref={containerRef} style={{width: "80vw", height: "85vh"}}/>
    }

    var labelHeight = Number(label[0].form.label_page_height) - Number(label[0].form.label_top_border) - Number(label[0].form.label_bottom_border);
    labelHeight /= Math.max(1, Number(label[0].form.label_count_y));
    var labelWidth = Number(label[0].form.label_page_width) - Number(label[0].form.label_left_border) - Number(label[0].form.label_right_border);
    labelWidth /= Math.max(1, Number(label[0].form.label_count_x));

    var scaleX = curWidth / Math.max(1, labelWidth);
    var scaleY = 1.0 * curHeight / Math.max(1, labelHeight);
    var scale = Math.min(scaleX, scaleY);

    scaleX = scale * labelWidth / 105;
    scaleY = scale * labelHeight / 105;

    //console.log("LayoutEditor", curWidth, curHeight, labelWidth, labelHeight, scaleX, scaleY);

    var fields = label.map((field, index) => {
        var fieldValue = field.name;
        if (field.form && field.form.data) {
            fieldValue = field.form.data.sample || field.form.data.value || field.form.data.prompt || fieldValue;
            }

        var frameColor = "black";
        var frameWidth = 0.1;
        if (selected == index) {
            frameColor = "red";
            frameWidth = 0.5;
            } else {
            if (depSelected.includes(index)) {
                frameColor = "green";
                frameWidth = 0.5;
                }
            }


        var {x, y, width, height, rotation} = extractPosition(field, snap);

        return <Field
            {...field}
            key={index}
            x={x}
            y={y}
            width={width}
            height={height}
            rotation={rotation}
            text1={field.text1}
            text2={field.text2}
            color={field.color}
            selected={selected}
            fieldId={index}
            type={field.type}
            image={field.image}
            frameColor={frameColor}
            frameWidth={frameWidth}
            onSelect={handleSelect}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onUpdate={handleUpdate}
            draggable={selected==index && transformerActive}/>;

        });

    var viewport = extractPosition(label[0], snap);
    viewport.x += 2
    viewport.y += 2

    return  <div ref={containerRef} style= {{width: "80vw", height: "85vh"}}>
                <Stage ref={stageRef} scaleX={scaleX} scaleY={scaleY} width={curWidth} height={curHeight} onClick={handleUnselect} onTap={handleUnselect}>
                    <Layer>

                        <Image ref={imageRef} scaleX={1} scaleY={1} x={0} y={0} width={100} height={100} image={img} />

                        <Group ref={viewportRef} draggable={outerSelected} x={viewport.x} y={viewport.y} width={viewport.width} height={viewport.height} scaleX={viewport.width/100}  scaleY={viewport.height/100}  onDragStart={handleOuterDragStart} onDragEnd={handleOuterDragEnd} rotation={0} dragBoundFunc={handleDragBoundLabel}>
                            <Rect ref={innerRef} x={0} y={0} width={100} height={100} fill={"lightblue"}  opacity={0.3} onClick={handleOuterSelect} />

                            {fields}
                            <Transformer ref={transformerRef}
                                     dragBoundFunc={handleDragBoundInner}
                                     onTransformEnd={handleTransformEnd}
                                     rotateEnabled={false} />
                        </Group>

                        <Transformer ref={outerTransformerRef}
                                     onTransformEnd={handleOuterTransformEnd}
                                     rotateEnabled={false} />
                    </Layer>
                </Stage>
            </div>;

    }

const LayoutEditorExport = forwardRef((props, ref) => <LayoutEditor {...props} forwardedRef={ref} />);

export default LayoutEditorExport;
