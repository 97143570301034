import React, {useState} from "react";

import {Stack, Typography}  from "@mui/material";

import {useTranslation}  from "react-i18next";

import Select from "../myselect";
import ActionButton from "./actionbutton";

const Output = ({}) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);

    const handleChange = (event) => {
        setSelected(event.target.value);
        }

    const handleOpenJob = () => {
        console.log("handleOpenJob", selected);
        }

    const handleDeleteJob = () => {
        console.log("handleDeleteJob", selected);
        }

    const handleJobQueue = () => {
        console.log("handleJobQueue");
        }

    var jobs= [];

    return <Stack direction={"column"}>
        <Typography variant={"h6"}>{t("Jobs")}</Typography>
        <Select>
            {jobs}
        </Select>

        <Stack directions={"row"}>
            <ActionButton onClick={handleOpenJob} disabled={selected==null}>{t("Open Job")}</ActionButton>
            <ActionButton onClick={handleDeleteJob} disabled={selected==null}>{t("Delete Job")}</ActionButton>
        </Stack>

        <Typography variant={"h6"}>Job Preview</Typography>
        <img src={imgSrc} />

        <ActionButton onClick={handleJobQueue}>{t("Job Queue ...")}</ActionButton>

    </Stack>
    }

export default Output;

