import React, {createContext} from "react";

export const fullscreenContext = createContext();

const FullscreenProvider = ({container, children}) => {

    const getModalProps = () => {
        return {container: container?.current ?? document.body};
        }
    return <fullscreenContext.Provider value={getModalProps}>
        {children}
    </fullscreenContext.Provider>
    }

export default FullscreenProvider;
