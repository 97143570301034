import React from "react";
import {Rect} from "react-konva";

import Barcode from "./barcode";
import barcode from "./barcode";

export const  BarcodeMap = {
    "EAN-8": {code:"ean8", opt:{}, prefix:""},
    "EAN-13": {code: "ean13", opt:{}, prefix:""},
    "QR-H":  {code: "qrcode", opt: {eclevel: "H"}, prefix:""},
    "QR-L" :  {code: "qrcode", opt: {eclevel: "L"}, prefix:""},
    "QR-M":  {code: "qrcode", opt: {eclevel: "M"}, prefix:""},
    "QR-Q":  {code: "qrcode", opt: {eclevel: "Q"}, prefix:""},
    "Standard-39": {code: "code39", opt: {includecheck: true}, prefix:""},
    "Standard-39-NoCheck": {code: "code39", opt: {}, prefix:""},
    "Extended-39": {code: "code39ext", opt: {includecheck: true}, prefix:""},
    "Extended-39-NoCheck": {code: "code39ext", opt:{}, prefix:""},
    "Standard-93": {code: "code93", opt: {}, prefix:""},
    "Extended-93": {code: "code93ext", opt: {}, prefix:""},
    "Code-128":{code: "code128", opt: {}, prefix:""},
    "EAN-128":{code: "gs1-128", opt: {}, prefix:""},
    "Interleaved 2 of 5":{code: "interleaved2of5", opt: {}, prefix:""},
    "Datamatrix":{code: "datamatrix", opt: {}, prefix:""},
    "GS1 Databar Omnidirectional":{code: "databaromni", opt: {}, prefix:"(01)"},
    "GS1 Databar Expanded":{code: "databarexpanded", opt:{}, prefix:""},
    "GS1 Datamatrix":{code: "gs1datamatrix", opt: {}, prefix:""},
    "Codabar": {code: "rationalizedCodabar", opt: {}, prefix:""},
    "Code-11":{code: "code11", opt: {}, prefix:""},
    "POSTNET": {code: "postnet", opt: {}, prefix:""},
    "USPS_4State": {code: "royalmail", opt: {}, prefix:""},
    "MSI": {code: "msi", opt: {}, prefix:""}
    };

const BarField = ({width, height, form, }) => {

   const barcodeType = form.barcodeType;
   const barcodeDetails = BarcodeMap[barcodeType];

   var barcodeDisplay = null;

   if (barcodeDetails) {

       const text1 = barcodeDetails.prefix + (form.data.sample || form.data.value || form.data.prompt);
       const color = "#" + form.color;

       const bcType = BarcodeMap[form.barcodeType].code;
       const humanReadable = form.humanReadable;

       barcodeDisplay =  <Barcode width={width} height={height} x={0} y={0} barcodeType={bcType} barcodeValue={text1}
                         angle={0} barcodeOptions={{...barcodeDetails.opt, barcolor: color, includetext: humanReadable}} />;
       }

   return <>
                <Rect x={0} y={0}  scaleX={1} scaleY={1} width={width} height={height} fill={"lightgray"} opacity={0.8} />
                {barcodeDisplay}
        </> ;
    }

export default BarField;
