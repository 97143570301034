
import React, {useRef, useState, useEffect} from "react";
import {Group, Rect} from "react-konva";

import TextField from "./textfield";
import BoxField from "./boxfield";
import FrameField from "./framefield";
import BarField from "./barfield";
import BitmapField from "./bitmapfield";
import LineField from "./linefield";
import PriceField from "./pricefield";

const FieldMap = {
    bar: BarField,
    bitmap: BitmapField,
    bitmapGrid: BitmapField,
    box: BoxField,
    container: FrameField,
    field: TextField,
    //input: TextField,
    line: LineField,
    multi: TextField,
    price: PriceField,
    //sublabel: FrameField,
    //"#Label": FrameField
    };

const Field = (props) => {
    var shapeRef = useRef(null);

    var {fieldId, x,y ,width, height, rotation, draggable, onDragStart, onDragEnd, type, frameColor="black", frameWidth=0.1, selected, onSelect} =  props;

    const [editing, setEditing] = useState(false);

    const handleClick = (event) => {

        onSelect(fieldId, event.evt.shiftKey, shapeRef);
        }

    const handleDblClick = (event) => {
        console.log("double click");
        setEditing(true);
        }

    const handleDragBoundFunc = (newPos, event) => {
        console.log("handleDragBoundInner", newPos, event);
        return newPos;
        }

    useEffect(() => {
        if (fieldId != selected) {
            setEditing(false);
            }
        }, [selected, fieldId]);


    var DisplayField = FieldMap[type];

    if (DisplayField == undefined) {
        return null;
        }

    var ret = <>
                <Group ref={shapeRef} x={x} y={y}  scaleX={1} scaleY={1} width={width} height={height}
                       rotation={rotation} onClick={handleClick} onDblClick={handleDblClick} onTap={handleClick} onDblTap={handleDblClick} draggable={draggable}
                       onDragStart={onDragStart} onDragEnd={onDragEnd} dragBoundFunc={handleDragBoundFunc}>

                    <DisplayField {...props}  editing={editing} onClick={handleClick} />
                    <Rect x={0} y={0}  scaleX={1} scaleY={1} width={width} height={height} stroke={frameColor} strokeWidth={frameWidth} padding={1}/>

                </Group>
            </>;

    return ret;
    }


export default Field;
