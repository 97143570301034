import React, {useState, useEffect} from "react";

import {atom, useSetAtom, useAtom, useAtomValue} from "jotai";

import {getDesign, saveDesign} from "./server";
import {NewToOld, OldToNew} from "./conversion";

const labelAtom = atom([]);
const labelIdAtom = atom(null);
const loadedLabelIdAtom = atom(null);
const labelDataIdAtom = atom((get) => {
    const label = get(labelAtom);
    return label[0]?.form?.label_data;
    });

const referenceLabelAtom = atom([]);
const referenceLabelIdAtom = atom(null);
const loadedReferenceLabelIdAtom = atom(null);

const LabelProvider = ({children}) => {
    const [label, setLabel] = useAtom(labelAtom);
    const labelId = useAtomValue(labelIdAtom);
    const [loadedLabelId, setLoadedLabelId] = useAtom(loadedLabelIdAtom);
    const [referenceLabelId, setReferencelabelId] = useAtom(referenceLabelIdAtom);
    const [referenceLabel, setReferenceLabel] = useAtom(referenceLabelAtom);
    const [loadedReferenceLabelId, setLoadedReferenceLabelId] = useAtom(loadedReferenceLabelIdAtom);

    useEffect(() => {
        let valid = true;

        const fetchData = async () => {
            var data = await getDesign(labelId);
            if (valid) {
                var newLabel = OldToNew(data);
                setLabel(newLabel);
                setLoadedLabelId(labelId);
                }
            }

        if (labelId != loadedLabelId) {
            fetchData();
            }

        return () => {
            valid = false;
            }
        }, [labelId, loadedLabelId]);

    useEffect( () => {
        let valid = true;

        const fetchData = async() => {
            var data = await getDesign(referenceLabelId);
            if (valid) {
                var newLabel = OldToNew(data);
                setReferenceLabel(newLabel);
                setLoadedReferenceLabelId(referenceLabelId);
                }
            }

        if (referenceLabelId != loadedReferenceLabelId) {
            fetchData();
            }

        return () => {
            valid = false;
            }
        }, [referenceLabelId, loadedReferenceLabelId]);

    return children;
    }

export const useLabel = () => {
    return useAtom(labelAtom);
    }

export const useLabelId = () => {
    return useAtom(labelIdAtom);
    }

export const useSetLabelId = () => {
    return useSetAtom(labelIdAtom);
    }

export const useLabelDataId = () => {
    return useAtomValue(labelDataIdAtom);
    }

export const useSetReferenceLabelId = () => {
    return useSetAtom(referenceLabelIdAtom);
    }

export const useReferenceLabelValue = () => {
    return useAtomValue(referenceLabelAtom);
    }

export const useSaveLabel = () => {
    const label = useAtomValue(labelAtom);
    const [labelId, setLabelId] = useAtom(labelIdAtom);
    const setLoadedLabelId = useSetAtom(loadedLabelIdAtom);

    const saveLabel = async (copy) => {
        var oldLabelFormat = NewToOld(label);

        var nextId = copy ? null : labelId;

        var newId = await saveDesign(oldLabelFormat, nextId);
        setLabelId(newId);
        setLoadedLabelId(newId);
        }

    return saveLabel;
    }

export default LabelProvider;
