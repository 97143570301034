import React, {useState, createContext, useContext, useEffect} from "react";

import {useTranslation} from "react-i18next";

import {atom, useAtom, useAtomValue, useSetAtom} from "jotai";

import {getLabelEditorCommands} from "./server";

const labelEditorCommandsAtom = atom({});

export const LabelEditorCommandsProvider = ({children}) => {
    const setCommands = useSetAtom(labelEditorCommandsAtom);
    const {t} = useTranslation();

    useEffect(() => {
        let valid = true;

        const fetchData = async () => {

            var data = await getLabelEditorCommands(t("currentLanguage"))

            if (valid) {
                setCommands(data);
               }
            }

        fetchData();

        return () => {
            valid = false;
            }
        }, [t]);

    return children;
    }

export const useLabelEditorCommands = () => {
    return useAtomValue(labelEditorCommandsAtom);
    }

export default LabelEditorCommandsProvider;
