import React, {useState, useEffect, useMemo} from "react";
import {Rect, Text, Group} from "react-konva";
import {Html} from "react-konva-utils";

import EditorSt from "./editorst";
import {TextEditor} from "./texteditor";
import {useFont} from "./fontloader";

const hAlignMap = {
    "W": "left",
    "NW": "left",
    "SW": "left",
    "E": "right",
    "NE": "right",
    "SE": "right",
    "Z": "center",
    "N": "center",
    "S": "center"
    };

const vAlignMap = {
    "W": "middle",
    "NW": "top",
    "SW": "bottom",
    "E": "middle",
    "NE": "top",
    "SE": "bottom",
    "Z": "middle",
    "N": "top",
    "S": "bottom"
    };

const TextField = ({x, y, width, height, form, selected, fieldId, type, editing, showValue, onClick, onUpdate}) => {
    const [canvas, setCanvas] = useState(document.createElement("canvas"));
    const [context, setContext] = useState(canvas.getContext("2d"));
    const curValue = useMemo(() => form.data.sample || form.data.value || form.data.prompt, [form]);

    const handleChange = (event) => {
        console.log(event);
        onUpdate(event.target.value);
        }

    const handleEditorChange = (newValue) => {
        onUpdate(newValue);
        }


   if (editing) {
        var bgColor = "yellow";
        } else {
        var bgColor= "lightgray";
        }

   var text1 = showValue ?? curValue;
   var color = "#" + form.color;
   var fontFamily = useFont(form.font);
   var minFontSize = Number(form.minFontSize) / 10;
   var maxFontSize = Number(form.fontSize) / 10;

   context.font = maxFontSize + "px "+ fontFamily;

   var splited = text1.split("\n");
   var maxMetric = {width:0, actualBoundingBoxAscent:0, actualBoundingBoxDescent:0};

   splited.forEach((line) => {
        var metrics = context.measureText(line);
        maxMetric.width = Math.max(maxMetric.width, metrics.width);
        maxMetric.actualBoundingBoxAscent += metrics.fontBoundingBoxAscent;
        maxMetric.actualBoundingBoxDescent += metrics.fontBoundingBoxDescent;
        });

   let metrics = maxMetric; // context.measureText(text1);

   let calculatedFontSizeWidth = Math.max(minFontSize, maxFontSize * (width-2) / metrics.width);
   let calculatedFontSizeHeight = Math.max(minFontSize, maxFontSize * (height) / (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent));
   let calculatedFontSize = Math.min(calculatedFontSizeWidth, calculatedFontSizeHeight)
   calculatedFontSize = Math.min(maxFontSize, calculatedFontSize);

   if ( isNaN(calculatedFontSize) || (!isFinite(calculatedFontSize))) {
        calculatedFontSize = 1;
        }

   var hAlin = hAlignMap[form.align];
   var vAlin = vAlignMap[form.align];

   if (type == "multi") {
       var variant = "textarea";
        } else {
         var variant = "input";
        }

   return <>

            <Rect x={0} y={0}  scaleX={1} scaleY={1} width={width} height={height}
                    fill={bgColor} opacity={0.8} padding={1}/>

            <EditorSt x={0.5} y={0.05} scaleX={1} scaleY={1} width={width-1} height={height-1} text={curValue} showValue={text1}
                  fontFamily={fontFamily} fontSize={calculatedFontSize} fill={color} stroke={color} strokeWidth={0.1}
                  align={hAlin} verticalAlign={vAlin} selected={selected} fieldId={fieldId} onChange={handleEditorChange}
                  variant={variant} editing={editing} Viewer={Text}
                   />

        </>;
   }

export default TextField;
