import React, {createContext, useState, useEffect, useContext} from "react";

import {atom, useAtom, useAtomValue, useSetAtom} from "jotai";

import {getDataStructure} from "./server";

import {useLabelDataId} from "./labelprovider";

const defaultDataStructure = {values: []};

const dataStructureAtom = atom(defaultDataStructure);
const dataStructureIdAtom = atom(null);

export const DataStructureProvider = ({children}) => {
    const labelDataId = useLabelDataId();
    const [dataStructureId, setDataStructureId] = useAtom(dataStructureIdAtom);
    const setDataStructure = useSetAtom(dataStructureAtom);

    useEffect(() => {
        let valid = true;

        const fetchData = async () => {
            try {
                var data = await getDataStructure(labelDataId);
                if (valid) {
                    setDataStructureId(labelDataId);
                    setDataStructure(data);
                    }
                } catch (err) {
                console.log(err);
                if (valid) {
                    setDataStructureId(labelDataId);
                    setDataStructure(defaultDataStructure);
                    }
                }
            }

        if (labelDataId) {
            if (dataStructureId != labelDataId) {
                fetchData();
                }
            } else {
            setDataStructureId(dataStructureId);
            setDataStructure(defaultDataStructure);
            }
        return () => {
            valid = false;
            };
        }, [dataStructureId, labelDataId]);

    return children;
    }

export const useDataStructure = () => {
    return useAtomValue(dataStructureAtom);
    }
export default DataStructureProvider;




