import React from "react";

import {TableRow, TableCell, IconButton} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import {useTranslation} from "react-i18next";

import TextAttribute from "./textattribute";
import ResourceAttribute from "./resourceattribute";


const MappingLine = ({value=[], index, keyName, resourceType, withUpload, resourceCol, onDelete, onChange}) => {
    const {t} = useTranslation();

    const handleChange = (key, newValue) => {
        var newRecord = structuredClone(value);
        newRecord[key] = newValue;
        onChange(index, newRecord);
        }

    const handleDelete = () => {
        onDelete(index);
        }

    var values = value.map((thisValue, index) => {
        return <TableCell key={index}>
                {(index == resourceCol) &&
                    <ResourceAttribute value={thisValue}
                                       keyName={index}
                                       name={t("Source")}
                                       resourceType={resourceType}
                                       withUpload={withUpload}
                                       variant={"toolbar"}
                                       onChange={handleChange}/>
                    }

                {(index != resourceCol) &&
                    <TextAttribute value={thisValue} keyName={index} name={t("Target")} width={100} onChange={handleChange}/>
                    }

                </TableCell>;
        });

    return  <TableRow>
                {values}
                <TableCell><IconButton onClick={handleDelete}><DeleteIcon /></IconButton></TableCell>
            </TableRow>;
    }

export default MappingLine;
