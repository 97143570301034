import React from "react";

import DataAttribute from "./dataattribute";

const ResourcefieldElement = ({value, keyName, rule, onChange }) => {

    console.log("ResourcefieldElement", value, keyName, rule, onChange)

    return <DataAttribute value={value}
                          keyName={keyName}
                          name={rule.caption}
                          resourceType={rule.fileSelect}
                          userData={rule}
                          onChange={onChange}/>;
    }

export default ResourcefieldElement;
