import React, {useState, useEffect} from "react";

import {getSseUrl} from "./server";

const SseSource = ({channel, onData}) => {

    useEffect(() =>{
        console.log("SseSource", channel);
        if (channel) {
            var url = getSseUrl(channel);

            const source = new EventSource(url);
            source.onmessage = (event) => {
                console.log("SSE", event.data);
                onData(JSON.parse(event.data));
                }
            return () => {
                source.close();
                }
            }

        }, [channel])
    return
    }

export default SseSource;
