import React, {useState, useContext} from "react";

import {Dialog} from "@mui/material";
import {fullscreenContext} from "./fullscreenprovider";

const MyDialog = ({children, ...props}) => {
    const getModalProps = useContext(fullscreenContext);

    return <Dialog {...props} {...getModalProps()}>
            {children}
            </Dialog>
    }

export default MyDialog;
