import React, {useContext} from "react";
import {Tooltip} from "@mui/material";

import {fullscreenContext} from "../fullscreenprovider";

const MyTooltip = ({children, title=null, ...props}) => {
    const getModalProps = useContext(fullscreenContext);

    if (title == null) {
        return <span>{children}</span>;
        }
    return <Tooltip title={title} {...props} PopperProps={getModalProps()}>
            <span>{children}</span>
            </Tooltip>;
    }

export default MyTooltip;

