import React, {useContext, useEffect} from "react";

import {MenuItem} from "@mui/material";

import SelectAttribute from "./selectattribute";
import YesNoAttribute from "./yesnoattribute";
import ResourceAttribute from "./resourceattribute";

import {ContainersContext, CurrentContainerContext} from "../containers";

const SelectElement = ({value, keyName, rule, resources, onChange}) => {
    var options =  [];

    useEffect(() => {
        var newValue = value ?? rule.value;
        if (value != newValue) {

            onChange(keyName, newValue, rule);
           }

        }, [keyName, rule]);

    if (rule.yn) {
        return <YesNoAttribute name={rule.caption} value={value} keyName={keyName} userData={rule} onChange={onChange}/>;
        }

    if (rule.fileSelect) {
        var upload = true;
        if (rule.fileSelect == "Design") {
            upload = false;
            }
        return <ResourceAttribute value={value}
                                  keyName={keyName}
                                  name={rule.caption}
                                  resources={resources}
                                  resourceType={rule.fileSelect}
                                  withUpload={upload}
                                  userData={rule}
                                  onChange={onChange}/>;
        }

    if (rule.options) {
        var optionList = Object.entries(rule.options);

        var options = optionList.map((keyVal, index) => {

            var [key, value] = keyVal;

            return <MenuItem key={key} value={key}>{value}</MenuItem>;
            });
        }

    if (rule.link == "Container") {
        return <ContainerElement name={rule.caption} keyName={keyName} value={value} userData={rule} onChange={onChange} />
        }

    return <SelectAttribute name={rule.caption} value={value} keyName={keyName} userData={rule} onChange={onChange}>
        {options}
        </SelectAttribute>;
    }

const ContainerElement = ({value, keyName, name, userData=null, onChange}) => {
    const containers = useContext(ContainersContext);
    const currentContainer = useContext(CurrentContainerContext);

    if (containers && containers.length == 0) {
        return;
        }

    var optionCount = 0;
    var options = containers.map(([guid, name], index) => {
        if (guid != currentContainer) {
            optionCount += 1;
            return <MenuItem key={guid} value={guid}>{name}</MenuItem>;
            }
        });

    if (optionCount == 0) {
        return;
        }

    return <SelectAttribute name={name} value={value} keyName={keyName} userData={userData} onChange={onChange}>
        {options}
        </SelectAttribute>;
    }

export default SelectElement;
