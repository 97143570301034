import React from "react";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from "dayjs";
import "dayjs/locale/de";

const DateAttribute = ({value, keyName, name, dateFormat, userData=null, minDate, maxDate, onChange}) => {

    const handleChange = (date) => {
        onChange(keyName, date.toISOString().slice(0, 10), userData);
        }

    var wrkMinDate = null;
    var wrkMaxDate = null;
    var wrkValue = dayjs(value);

    if (minDate) {
        wrkMinDate = dayjs().add(Number(minDate), "day");
        if (!value) {
            if (wrkValue < wrkMinDate) {
                wrkValue = wrkMinDate;
                }
            }
        }

    if (maxDate) {
        wrkMaxDate = dayjs().add(Number(maxDate), "day");
        if (!value) {
            if (wrkValue > wrkMaxDate) {
                wrkValue = wrkMaxDate;
                }
            }
        }

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                <div>
                    <DatePicker label={name} value={wrkValue} minDate={wrkMinDate} maxDate={wrkMaxDate} onChange={handleChange} sx={{padding: 1}}/>
                </div>
            </LocalizationProvider>;

    }

export default DateAttribute;
