import React, {useRef, useState, useEffect} from "react";

import bwipjs from "bwip-js";
import {Image} from "react-konva";

const Barcode = ({x, y, width, height, barcodeType, barcodeValue, barcodeOptions}) => {

     const [canvas, setCanvas] = useState(document.createElement("canvas"));
     const [context, setContext] = useState(canvas.getContext("2d"));
     const imageRef = useRef();
     const [dataUrl, setDataUrl] = useState("");

     useEffect(() => {
         try {
             context.clearRect(0, 0, canvas.width, canvas.height);

             bwipjs.toCanvas(canvas,
                 {
                     bcid: barcodeType,
                     text: barcodeValue,
                     scale: 5,
                     includetext: false,
                     textalign: "center",
                     ...barcodeOptions
                 });

             var newDataUrl = canvas.toDataURL("image/png");

             if (dataUrl != newDataUrl) {
                 setDataUrl(newDataUrl);
             }
         } catch (e) {
             console.log(e);
         }

         if (imageRef.current) {
             imageRef.current.getLayer().batchDraw();
         }
     }, [barcodeType, barcodeValue, barcodeOptions, imageRef, dataUrl]);

    return <Image ref={imageRef} scaleX={1} scaleY={1} x={x} y={y} width={width} height={height} image={canvas} />;
    }

export default Barcode;
