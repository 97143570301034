import React, {useState} from "react";

import {Tabs, Tab, Box} from "@mui/material";

import {DformViewer} from "./dform";
import CustomTabPanel from "./customtabpanel";

const TabsElement= ({rule, value, keyName, preProc=null, onChange}) => {
    const [activeTab, setActiveTab] = useState( Object.keys(rule.entries)[0]);

    const handleTabChange = (event, value) => {
        setActiveTab(value);
        }

    var activeTabContent = null;

    var tabs = Object.keys(rule.entries).map((key, index) => {
        if (key == activeTab) {
            activeTabContent = <DformViewer key={key}
                                            keyName={keyName}
                                            value={value}
                                            rules={rule.entries[key].elements}
                                            preProc={preProc}
                                            onChange={onChange}/>;
            }
        return <Tab key={key} value={key} label={rule.entries[key].caption} />;
        });

    return <>
            <Tabs value={activeTab} onChange={handleTabChange}>
                {tabs}
            </Tabs>
            <CustomTabPanel index={activeTab} value={activeTab}>
                <Box style={{padding: 20}}>
                    {activeTabContent}
                </Box>
            </CustomTabPanel>
        </>;
    }

export default TabsElement;
