import React, {useState, createContext, useContext, useEffect} from "react";

const FontContext = createContext(null);

import {getFontUrl} from "../server";

const FontLoader = ({children}) => {
    const [loadedFonts, setLoadedFonts] = useState(["Courier", "Courier-Bold", "Courier-BoldOblique", "Courier-Oblique",
                    "Helvetica","Helvetica-Bold", "Helvetica-BoldOblique","Helvetica-Oblique",
                    "Symbol",
                    "Times-Bold", "Times-BoldItalic", "Times-Italic","Times-Roman",
                    "ZapfDingbats"]);
    const [loadingFonts, setLoadingFonts] = useState([]);

    const loadFont = (fontName) => {
        console.log("loadFont", fontName);
        window.setTimeout(async () => {
            setLoadingFonts([...loadingFonts, fontName]);
            var url = getFontUrl(fontName)
            var font = new FontFace(fontName, `url(${url})`);
            try {
                var loadedFont = await font.load();
                document.fonts.add(loadedFont);
                setLoadedFonts([...loadedFonts, fontName]);
                setLoadingFonts(loadingFonts.filter((font) => font != fontName));
            } catch (error) {
                console.log("Error loading font", fontName, error);
                }
            }, 100);

        }

    return <FontContext.Provider value={[loadedFonts, loadingFonts, loadFont]}>
                {children}
            </FontContext.Provider>;
    }

export const useFont = (fontName) => {
    const [loadedFonts, loadingFonts, loadFont] = useContext(FontContext);

    if (loadedFonts.includes(fontName)) {
        return fontName
        }

    if (!loadingFonts.includes(fontName)) {
        loadFont(fontName);
        }

    return "Helvetica";
    }

export default FontLoader;

