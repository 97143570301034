import React from "react";
import {DialogContent, DialogTitle} from "@mui/material";

import Dialog from "../mydialog";
import Dform from "./dform";
import updateLabelField, {overwriteRule} from "../labelextensions";
import {useLabelEditorCommands} from "../commandsprovider";
import SelectFieldType from "./selectfieldtype";
import CopyReference from "./copyreference";

const Details = ({open, title, record, wizard=false, withType=true, disabled=false, onClose, onChange, onStore}) => {
    const rules = useLabelEditorCommands();

    var fieldType = record.type;

    var fieldRules = rules[fieldType].elements;
    var initRules = undefined;

    if (wizard) {
        initRules = fieldRules;
        fieldRules = rules[fieldType].wizard.elements;
        }

    return <Dialog open={open} onClose={onClose} maxWidth={"xl"}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    { withType &&
                        <>
                        <SelectFieldType value={record.type} onChange={onChange} />
                        <CopyReference record={record} onStore={onStore} />
                        </>
                        }
                    <Dform value={record.form}
                           rules={fieldRules}
                           initRules={initRules}
                           keyName={"form"}
                           preProc={overwriteRule}
                           postProc={updateLabelField}
                           disabled={disabled}
                           onChange={onChange}/>
                </DialogContent>
            </Dialog>;
    }

export default Details;
