import React, {useState} from "react";

import {TextField, DialogTitle, DialogContent, Button} from "@mui/material";

import {ChromePicker} from "react-color";

import {useTranslation} from "react-i18next";

import Dialog from "../mydialog";

const ColorAttribute = ({value, keyName, name, variant="text", userData=null, onChange}) => {

    const {t} = useTranslation();

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleChangeValue = (event) => {
        onChange(keyName, event.target.value, userData);
        }

    const handleDoubleClick = (event) => {
        setDialogOpen(true);
        }

    const handleChange = (color) => {
        onChange(keyName, color.hex.substr(1), userData); // remove leading #;
        }

    const handleClose = (event) => {
        setDialogOpen(false);
        }

    var textColor = value;
    var wrk=parseInt(textColor, 16);

    var wrk= (114 *(wrk & 0xff) + 587*((wrk >>8) & 0xff) + 299*((wrk>>16) &0xff)) / 1000;

    var backgroundColor= "ffffff";
    if (wrk > 123) {
        var backgroundColor="000000"
        }

    var inputProps= {
        style: {
            color: "#"+textColor,
            backgroundColor: "#"+backgroundColor,
            }
        };

    var buttonProps= {
        backgroundColor: "#"+textColor,
        margin: 1
        };

    if (variant == "text") {
        return  <div style={{paddingTop: 20, paddingRight: 10}}>

                <fieldset style={{paddingTop: 10}}>

                    <legend style={{fontFamily: "Roboto"}}>{name}</legend>
                    <ChromePicker color={value}
                            onChange={handleChange}
                            disableAlpha={true}/>
                    </fieldset>
                </div>;
        }

    return <> {variant == "text" &&
                <>
                <TextField label={name}
                    value={value}
                    onChange={handleChangeValue}
                    onDoubleClick={handleDoubleClick}
                    InputProps={inputProps}
                    InputLabelProps={{shrink: true, focused: !value}} />

                <Button onClick={handleDoubleClick} variant={"contained"} color={"secondary"} sx={{margin: 1}}>{t("Select Color")}</Button>
                </>
                }

                {variant == "button" &&
                    <Button onClick={handleDoubleClick} variant={"contained"} sx={buttonProps} />
                    }

                <Dialog open={dialogOpen} onClose={handleClose}>
                    <DialogTitle>{t("Select Color")}</DialogTitle>
                    <DialogContent>
                        <ChromePicker color={value}
                            onChange={handleChange}
                            disableAlpha={true}/>
                    </DialogContent>
                </Dialog>
            </>;
    }

export default ColorAttribute;
