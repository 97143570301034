import React, {useState, createContext, useContext, useEffect} from "react";

import {atom, useAtom, useSetAtom, useAtomValue} from "jotai";

import {isEqual} from "lodash";

import {getUserInfo} from "./server";

const userInfoAtom = atom(null);
const userInfoRefreshAtom = atom(null);

export const UserInfoProvider = ({children}) => {
    const setUserInfo = useSetAtom(userInfoAtom);
    const setUserInfoRefresh = useSetAtom(userInfoRefreshAtom);

    const handleRefresh = () => {
        var newUserInfo = getUserInfo();
        setUserInfo(newUserInfo);
        }

    const handleMsgReceived = (event) => {
        if (event.source != window.parent) {
            if (["signOn", "signOff"].includes(event.data.op)) {
                console.log("handleMsgReceived", event)
                handleRefresh();
                }
            }
        }

    useEffect(() => {

        window.addEventListener("message", handleMsgReceived, false);
        return () => {
            window.removeEventListener("message", handleMsgReceived);
            }
        }, []);

    useEffect(() => {
        setUserInfoRefresh(handleRefresh);
        setUserInfo(getUserInfo());
        }, []);

    return children;
    }

export const useUserInfo = () => {
    return useAtomValue(userInfoAtom);
    }

export const useUserInfoRefresh = () => {
    return useAtomValue(userInfoRefreshAtom);
    }

