import React, {Fragment, useState, useRef} from "react";
import { Portal} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

import {useTranslation} from "react-i18next";

import ToolbarPositioning from "./toolbarpositioning";

import {CurrentContainerContext} from "../containers";

import ToolbarEntry, {ToolbarSelection} from "./toolbarentry";

import Details from "./details";

const AttributeEditor = ({record, portal, wizardOpen, detailsOpen, onStore, onEndSelection, onDelete, onBack, onCloseWizard, onDetailsOpen, onDetailsClose}) => {
    const {t} = useTranslation();

    const [tab, setTab] = useState("General");

    const handleChange = (key, value)  => {
        // console.log("AttributeEditor.change", key, value);
        var newRecord = structuredClone(record);
        newRecord[key] = value;

        onStore(newRecord);
        }

    const handleFormChange = (key, value, userData)  => {
        var newRecord = structuredClone(record);
        newRecord.form[key] = value;
        onStore(newRecord);
        }

    const handleFormAlignChange = (key, value)  => {
        //const newValue = alignTransform[record.form.align][value];
        console.log(record.form.align, value, newValue);
        handleFormChange("align", newValue);
        }

    const handleDelete = (event) => {
        onEndSelection();
        onDelete(event);
        }

    const handleBack = (event) =>  {
        onEndSelection();
        onBack(event);
        }
    const handleTabChange = (event, value) => {
        setTab(value);
        }

    const handleFieldTypeChange = (event) => {
        handleChange("type", event.target.value);
        }


    return <>
            <Portal container={portal.current}>
                <ToolbarEntry name={t("Details")} iconTrue={SettingsApplicationsIcon} onChange={onDetailsOpen} />
                <ToolbarEntry name={t("Delete")} iconTrue={DeleteIcon} onChange={handleDelete} />
                <ToolbarEntry name={t("Behind")} iconTrue={KeyboardDoubleArrowDownIcon} onChange={handleBack} />

                {["bar", "bitmap", "bitmapGrid", "box", "field", "line", "multi", "price"].includes(record.type) &&
                    <ToolbarPositioning record={record} onFormChange={handleFormChange} />
                    }

            </Portal>

            <CurrentContainerContext.Provider value={record.form.guid}>
                {wizardOpen &&
                    <Details title={t("Wizard")} open={wizardOpen} wizard onClose={onCloseWizard} onChange={handleChange} onStore={onStore} record={record} />
                    }
                {!wizardOpen &&
                    <Details title={t("Details")} open={detailsOpen} onClose={onDetailsClose} onChange={handleChange} onStore={onStore} record={record} />
                    }
            </CurrentContainerContext.Provider>
        </> ;
    }

export default AttributeEditor;
