import React, {useState, useMemo, useEffect} from "react";

import {Button, Typography, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PageviewIcon from "@mui/icons-material/Pageview";

import {useTranslation} from "react-i18next";

import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

import "./joblist.css";

import {getJobList, updJob} from "./server";
import SseSource from "./ssesource";
import LocalizeIso from "./localizeiso";
import MyTooltip from "./attributeeditor/mytooltip";

import {useSetPath} from "./myrouter";
import {useUserInfo} from "./userinfoprovider";

const JobList = ({}) => {
    const {t} = useTranslation();
    const [jobList, setJobList] = useState([]);
    const [channel, setChannel] = useState(null);
    const setPath = useSetPath();
    const userInfo = useUserInfo();

    const handleData = (data) => {
        console.log("handleData", data);
        setJobList((oldList) => {
            var newList = [...oldList];

            var index = newList.findIndex((item) => item.id == data.id);
            if (index >= 0) {
                if (data.status == "Deleted") {
                    newList = newList.filter((item) => item.id != data.id);
                    } else {
                    newList[index] = data;
                    }
                } else {
                newList.push(data);
                }
            return newList;
            });
        }


    const renderPreview = (cell) => {
        const handlePreview = () => {
            console.log("Preview", cell.value);
            setPath("/display" + cell.data.href);
            }
        return <img onClick={handlePreview} src={cell.value} />;
        }

    const renderAction = (cell) => {

        const handleDelete = () => {
            updJob(cell.data.id, {status: "Deleted"});
            }
        const handleViewPDF = () => {
            console.log("View PDF", cell.data.id);
            setPath("/display" + cell.data.href);
            }
        return <>
            <MyTooltip title={t("Delete")}>
                <IconButton onClick={handleDelete}><DeleteIcon /></IconButton>
            </MyTooltip>
            <MyTooltip title={t("Open")}>
                <IconButton onClick={handleViewPDF}><PageviewIcon /></IconButton>
            </MyTooltip>
            </>;
        }

    const renderTimestamp = (cell) => {
        return <Typography> {LocalizeIso(cell.value, t("yyyy-MM-dd HH:mm:ss"))} </Typography>;
        }

    const gridOptions = {
         defaultColDef: {
            enableCellChangeFlash: true
            }
        };

    const columnDefs = useMemo( () => {
        return [
            {field: "preview", headerName: t("Preview"), cellRenderer: renderPreview},
            {field: "description", headerName: t("Description"), sortable: true},
            {field: "status", headerName: t("Status"), sortable: true, enableCellChangeFlash: true},
            {field: "timestamp", headerName: t("Timestamp"), sortable: true, sort: "desc", cellRenderer: renderTimestamp},
            {field: "size", headerName: t("Size"), sortable: true, enableCellChangeFlash: true},
            {field: "status", headerName: t("Action"), cellRenderer: renderAction}
            ];
        }, [t]);

    useEffect(() => {
        let valid = true;

        const fetchData = async () => {
            var data = await getJobList();
            if (valid) {
                setJobList(data.jobs);
                setChannel(data.token);
                }
            }

        fetchData();

        return () => {
            valid = false;
            }
        }, [userInfo]);

    console.log("Redraw joblist", jobList);

    return <div className={"ag-theme-material"} style={{height: "100vh", width: "100vw"}}>
            <AgGridReact gridOptions={gridOptions} rowData={jobList} columnDefs={columnDefs} animateRows={true}/>
            <SseSource channel={channel} onData={handleData} />
            </div>;
    }

export default JobList;
