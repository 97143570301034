import React from "react";

import {Card, CardHeader, CardContent, Typography, Box} from '@mui/material';

import {useTranslation} from "react-i18next";

import {TestSignal} from "./signals";

import version from "./version";

import {useUserInfo} from "./userinfoprovider";

const About = () => {
    const {t} = useTranslation();

    const userInfo = useUserInfo();

    return <Card>
            <CardHeader title={t("Next Generation Label Printing System")} />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t("Release: {{version}}", {version: version})}
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                    &copy; {t("Copyright 2023 by RSJ Software GmbH Germering. All rights reserved.")}
                </Typography>

                <Box height={20} />

                {userInfo &&
                    <>

                    <Typography variant="body2" color="textSecondary" component="p">
                        Name: {userInfo.nickName}
                    </Typography>

                    <Typography variant="body2" color="textSecondary" component="p">
                        Email: {userInfo.email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        License: {userInfo.license}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Account: {userInfo.userId}
                    </Typography>
                    </>
                    }
            </CardContent>
        </Card>;
    }

export default About;
