import React from "react";

import {DformViewer} from "./dform";

const DivElement = ({value, keyName, rule, preProc=null, postProc=null, onChange}) => {
    return <span>
            <DformViewer  value={value} keyName={keyName} rules={rule.html} preProc={preProc} onChange={onChange} span={true}/>
            </span>;
    }

export default DivElement;
