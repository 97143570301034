import React from "react";
import {MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";

import SelectAttribute from "./selectattribute";
import {useReferenceLabelValue} from "../labelprovider";

const CopyReference = ({record, onStore}) => {
    const referenceLabel = useReferenceLabelValue();
     const {t} = useTranslation();

    var [_, ...referenceFields] = referenceLabel;
    var referenceOptions = [];

    if (referenceFields) {

        referenceOptions = referenceFields.map((field, index) => {

            const handleCopy = () => {
                var newRecord = structuredClone(field);
                newRecord.position = record.position;
                newRecord.form.direction = record.form.direction;
                onStore(newRecord);
                }
            return <MenuItem key={index+1} onClick={handleCopy}>{field.form.name} - {field.form.description}</MenuItem>
            });
        }

    if (referenceOptions.length == 0) {
        return
        }

    return <SelectAttribute keyName={"copy"} name={t("Copy Field")}>
                {referenceOptions}
            </SelectAttribute>;
    }

export default CopyReference;
