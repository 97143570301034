import React, {useEffect} from "react";

import NumberAttribute from "./numberattribute";

const NumberElement = ({value, keyName, rule, onChange}) => {

    useEffect(() => {
        var newValue = value ?? rule.value;
        if (value != newValue) {

            onChange(keyName, newValue, rule);
           }

        }, [keyName, rule]);

    return <NumberAttribute name={rule.caption} value={value} keyName={keyName} min={rule.min} max={rule.max} required={rule.required} readOnly={rule.readonly=="true"} style={rule.style} userData={rule}  onChange={onChange}  />;
    }

export default NumberElement;
