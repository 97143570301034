import React, {useEffect} from "react";

import guid from "node-guid";

const HiddenElement = ({rule, value, keyName, onChange}) => {

    useEffect(() => {

        try {

            if (rule.class?.indexOf("containerGuid") > -1) {
                if (!value) {
                    onChange(keyName, guid.new(), rule);
                }
                return;
                }
            } catch (e) {

            }
        }, [keyName, rule, value]);

    useEffect(() => {

        if (value != rule.value) {

            onChange(keyName, rule.value ?? null, rule);
           }

        }, [keyName, rule, value]);
    }

export default HiddenElement;
