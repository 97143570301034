import React, {useRef} from "react";

import {Portal} from "@mui/material";
import {useTranslation} from "react-i18next";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";

import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";

import HeightIcon from "@mui/icons-material/Height";
import {WidthIcon, HorizontalDistributeIcon, VerticalDistributeIcon, AlignStretchIcon,  AlignJustifyStretchIcon} from "./extraicons";


import ToolbarEntry from "./attributeeditor/toolbarentry";

const AlignmentEditor = ({selected, depSelected, label, portal, onUpdate}) => {
    const {t} = useTranslation();

    const handleAlignLeft = () => {

        var newLabel = structuredClone(label);
        var refPosition = label[selected].position.left;

        depSelected.map((dep) => {
            if (refPosition + newLabel[dep].position.width <= 100) {
                newLabel[dep].position.left = refPosition;
                }
            });
         onUpdate(newLabel);
        }

    const handleAlignCenter = () => {
        var newLabel = structuredClone(label);
        var refPosition = label[selected].position.left + label[selected].position.width / 2;

        depSelected.map((dep) => {
            if (refPosition + newLabel[dep].position.width / 2 <= 100) {
                newLabel[dep].position.left = refPosition - newLabel[dep].position.width / 2;
                }
            });
         onUpdate(newLabel);

        }

    const handleAlignRight = () => {
        var newLabel = structuredClone(label);
        var refPosition = label[selected].position.left + label[selected].position.width;

        depSelected.map((dep) => {
            if (refPosition - newLabel[dep].position.width >= 0) {
                newLabel[dep].position.left = refPosition - newLabel[dep].position.width;
                }
            });
         onUpdate(newLabel);
        }

    const handleAlignTop = () => {
        var newLabel = structuredClone(label);
        var refPosition = label[selected].position.top;

        depSelected.map((dep) => {
            if (refPosition + newLabel[dep].position.height <= 100) {
                newLabel[dep].position.top = refPosition;
                }
            });
         onUpdate(newLabel);
        }

    const handleAlignMiddle = () => {
        var newLabel = structuredClone(label);
        var refPosition = label[selected].position.top + label[selected].position.height / 2;

        depSelected.map((dep) => {
            if (refPosition + newLabel[dep].position.height / 2 <= 100) {
                newLabel[dep].position.top = refPosition - newLabel[dep].position.height / 2;
                }
            });
         onUpdate(newLabel);
        }

    const handleAlignBottom = () => {
        var newLabel = structuredClone(label);
        var refPosition = label[selected].position.top + label[selected].position.height;

        depSelected.map((dep) => {
            if (refPosition - newLabel[dep].position.height >= 0) {
                newLabel[dep].position.top = refPosition - newLabel[dep].position.height;
                }
            });
        onUpdate(newLabel);
        }

    const handleSameWidth = () => {
        var newLabel = structuredClone(label);
        var refPosition = label[selected].position.width;

        depSelected.map((dep) => {
            if (refPosition + newLabel[dep].position.left <= 100) {
                newLabel[dep].position.width = refPosition;
                }

            });
        onUpdate(newLabel);
        }

    const handleSameHeight = () => {
        var newLabel = structuredClone(label);
        var refPosition = label[selected].position.height;

        depSelected.map((dep) => {
            if (refPosition + newLabel[dep].position.top <= 100) {
                newLabel[dep].position.height = refPosition;
                }
            });
        onUpdate(newLabel);

        }

    const handleSpaceHorizontal = () => {
        var newLabel = structuredClone(label);
        var minX = 99999999999;
        var maxX = 0;
        var sumWidth = 0;

        var fields = [selected, ...depSelected];

        fields.map((field) => {
            minX = Math.min(minX, label[field].position.left);
            maxX = Math.max(maxX, label[field].position.left + label[field].position.width);
            sumWidth += label[field].position.width;
            });

        var newSpace = ((maxX - minX) - sumWidth) / (fields.length - 1);

        fields = fields.sort((a, b) => {
            var leftA = label[a].position.left;
            var leftB = label[b].position.left;
            if (leftA > leftB) {
                return 1;
                } else {
                if (leftA == leftB) {
                    return 0;
                    } else {
                    return (-1);
                    }
                }
            });

        var curX = minX;

        fields.map((field) => {
            newLabel[field].position.left = curX;
            curX += label[field].position.width + newSpace;
            });

        onUpdate(newLabel);
        }

    const handleSpaceVertical = () => {
        var newLabel = structuredClone(label);
        var minY = 99999999999;
        var maxY = 0;
        var sumHeight = 0;

        var fields = [selected, ...depSelected];

        fields.map((field) => {
            minY = Math.min(minY, label[field].position.top);
            maxY = Math.max(maxY, label[field].position.top + label[field].position.height);
            sumHeight += label[field].position.height;
            });

        var newSpace = ((maxY- minY) - sumHeight) / (fields.length - 1);

        fields = fields.sort((a, b) => {
            var leftA = label[a].position.top;
            var leftB = label[b].position.top;
            if (leftA > leftB) {
                return 1;
                } else {
                if (leftA == leftB) {
                    return 0;
                    } else {
                    return (-1);
                    }
                }
            });

        var curY = minY;

        fields.map((field) => {
            newLabel[field].position.top = curY;
            curY += label[field].position.height + newSpace;
            });

        onUpdate(newLabel);
        }

    const handleFillHorizontal = () => {
        var newLabel = structuredClone(label);
        var minX = 99999999999;
        var maxX = 0;
        var sumWidth = 0;

        var fields = [selected, ...depSelected];

        fields.map((field) => {
            minX = Math.min(minX, label[field].position.left);
            maxX = Math.max(maxX, label[field].position.left + label[field].position.width);
            sumWidth += label[field].position.width;
            });

        var factor = (maxX - minX) / sumWidth;

        fields = fields.sort((a, b) => {
            var leftA = label[a].position.left;
            var leftB = label[b].position.left;
            if (leftA > leftB) {
                return 1;
                } else {
                if (leftA == leftB) {
                    return 0;
                    } else {
                    return (-1);
                    }
                }
            });

        var curX = minX;

        fields.map((field) => {
            newLabel[field].position.left = curX;
            newLabel[field].position.width *= factor;
            curX += newLabel[field].position.width;
            });

        onUpdate(newLabel);
        }

    const handleFillVertical = () => {
        var newLabel = structuredClone(label);
        var minY = 99999999999;
        var maxY = 0;
        var sumHeight = 0;

        var fields = [selected, ...depSelected];

        fields.map((field) => {
            minY = Math.min(minY, label[field].position.top);
            maxY= Math.max(maxY, label[field].position.top + label[field].position.height);
            sumHeight += label[field].position.height;
            });

        var factor = (maxY - minY) / sumHeight;

        fields = fields.sort((a, b) => {
            var leftA = label[a].position.top;
            var leftB = label[b].position.top;
            if (leftA > leftB) {
                return 1;
                } else {
                if (leftA == leftB) {
                    return 0;
                    } else {
                    return (-1);
                    }
                }
            });

        var curY = minY;

        fields.map((field) => {
            newLabel[field].position.top = curY;
            newLabel[field].position.height *= factor;
            curY += newLabel[field].position.height;
            });

        onUpdate(newLabel);
        }

    return <Portal container={portal.current}>
                <ToolbarEntry name={t("Same Width")} iconTrue={WidthIcon} onChange={handleSameWidth} />
                <ToolbarEntry name={t("Same Height")} iconTrue={HeightIcon}  onChange={handleSameHeight} />

                <ToolbarEntry name={t("Align Left")} iconTrue={FormatAlignLeftIcon}  onChange={handleAlignLeft} />
                <ToolbarEntry name={t("Align Center")} iconTrue={FormatAlignCenterIcon}  onChange={handleAlignCenter} />
                <ToolbarEntry name={t("Align Right")} iconTrue={FormatAlignRightIcon}  onChange={handleAlignRight} />

                <ToolbarEntry name={t("Align Top")} iconTrue={VerticalAlignTopIcon}  onChange={handleAlignTop} />
                <ToolbarEntry name={t("Align Middle")} iconTrue={VerticalAlignCenterIcon}  onChange={handleAlignMiddle} />
                <ToolbarEntry name={t("Align Bottom")} iconTrue={VerticalAlignBottomIcon}  onChange={handleAlignBottom} />

                <ToolbarEntry name={t("Space Horizontal")} iconTrue={HorizontalDistributeIcon}  onChange={handleSpaceHorizontal} />
                <ToolbarEntry name={t("Space Vertical")} iconTrue={VerticalDistributeIcon}  onChange={handleSpaceVertical} />

                <ToolbarEntry name={t("Fill Horizontal")} iconTrue={AlignJustifyStretchIcon}  onChange={handleFillHorizontal} />
                <ToolbarEntry name={t("Fill Vertical")} iconTrue={AlignStretchIcon}  onChange={handleFillVertical} />
            </Portal>
    }

export default AlignmentEditor;
