import React from "react";
import {Group} from "react-konva";
import {TextEditor} from "../layouteditor/texteditor";

export const EditorSt = React.forwardRef(({ text, onChange, editing, selected, fieldId, Viewer,  showValue, ...rest }, ref) => {

  const textRef = React.useRef(null);

  var wrkValue = showValue ?? text;

  return (
    <Group>
      <Viewer
        text={wrkValue}
        ref={textRef}
        width={100}
        visible={!editing}
        {...rest}
      />
      {editing && (
        <Group>
          <TextEditor
              {...rest}
              style={{ top: 5 }}
            value={text}
            bg={"yellow"}
            textNodeRef={textRef}
            onChange={onChange}
          />
        </Group>
      )}
    </Group>
    );
    });


export default EditorSt;
