{
  "translation": {
    "currentLanguage": "de",
    "yyyy-MM-dd HH:mm:ss": "yyyy-MM-dd HH:mm:ss",
    "Menu": "Menu",
    "Next Generation Label Printing": "Next Generation Label Printing",
    "Account": "Benutzer",
    "Label Editor": "Label Editor",
    "Job List": "Job Liste",
    "Dump translations": "Fehlende Übersetzungen",
    "About": "Über",
    "Loading": "Lädt",
    "Run": "Ausführen",
    "Save": "Sichern",
    "Source": "Source",
    "Undo": "Rückgängig",
    "Redo": "Doch",
    "Label": "Label",
    "Fields": "Felder",
    "Add Field": "Neues Feld",
    "Label Attributes": "Etiketten Attribute",
    "None": "Keines",
    "Upload {{resourceType}}": "{{resourceType}} hochladen",
    "Upload {{resourceType}} Resource": "{{resourceType}} Resource hochladen",
    "Upload a resource to the server.": "Eine Resource in den Server laden",
    "Resource Name": "Resource Name",
    "Resource Description": "Resource Beschreibung",
    "Select": "Auswählen",
    "Upload": "Hochladen",
    "Field List": "Feldliste",
    "Close": "Schliessen",
    "Type": "Typ",
    "Name": "Name",
    "Description": "Beschreibung",
    "Action": "Aktion",
    "Open": "Öffnen",
    "Details": "Details",
    "Delete": "Löschen",
    "Behind": "Dahinter",
    "Wizard": "Wizard",
    "Data": "Daten",
    "Direction": "Richtung",
    "Align": "Ausrichtung",
    "Vertical Align": "Vertikale Ausrichtung",
    "Color": "Farbe",
    "Font": "Schriftart",
    "Font Size": "Schriftgrösse",
    "Min Font Size": "Min Schriftgrösse",
    "Select Color": "Farbe auswählen",
    "Field Type": "Feldtyp",
    "Copy Field": "Feld kopieren",
    "Dynamic": "Dynamisch",
    "Help": "Hilfe",
    "Prompt": "Abfrage",
    "If set, field data will be prompted during label creation, using the prompt name entered here": "Wenn gesetzt, werden die daten vor Label Erzeugung mit dieser Abfrage angefordert)",
    "Text": "Text",
    "Number": "Zahl",
    "Date": "Datum",
    "Read Only": "Nur Lesen",
    "No Show": "Nich anzeigen",
    "XPath": "XPath",
    "Path to field content when using external data (like XML or Excel file)": "Pfad zum Feldinhalt bei Nutzung von externen Daten (wie XML oder Excel Dateien)",
    "Sample": "Beispiel",
    "Sample value to use for preview in layout editor": "Beispielwert für die Vorabanzeige im Layout Editor",
    "Mapping": "Abbildung",
    "Script": "Script",
    "Preview": "Vorab Anzeige",
    "Status": "Status",
    "Timestamp": "Zeitstempel",
    "Size": "Grösse",
    "Design List": "Design List",
    "Master": "Master",
    "Login": "Anmeldung",
    "Logout": "Abmeldung",
    "Created": "Erzeugt",
    "Designs": "Designs",
    "Back": "Zurück",
    "Designs only": "Nur Layouts",
    "Edit": "Ändern",
    "Package": "Packen",
    "Do you really want to delete the file?": "Wollen Sie diese Datei wirklich löschen?",
    "Confirmation": "Bestätigung",
    "No": "Nein",
    "Yes": "Ja",
    "Reset": "Zurücksetzen",
    "Store": "Speichern",
    "Next Generation Label Printing System": "Next Generation Label Printing System",
    "Release: {{version}}": "Release: {{version}}",
    "Copyright 2023 by RSJ Software GmbH Germering. All rights reserved.": "Copyright 2023 by RSJ Software GmbH Germering. All rights reserved.",
    "Save Copy": "Kopie speichern",
    "New": "Neu",
    "Stroke": "Strich",
    "Bitmap": "Bitmap",
    "Keep Ratio": "Seitenverhältnis beibehalten",
    "Create Label": "Neues Label",
    "Field Alignment": "Feld Ausrichtung",
    "Number of fields selected: {{number}}": "Anzahl der ausgewählten Felder : {{number}}",
    "Same Width": "Gleiche Breite",
    "Same Height": "Gleiche Höhe",
    "Align Left": "Links ausrichten",
    "Align Center": "Mittig ausrichtien",
    "Align Right": "Rechts ausrichten",
    "Align Top": "Oben ausrichten",
    "Align Middle": "Vertikal mittig ausrichten",
    "Align Bottom": "Unten ausrichten",
    "Space Horizontal": "Horizontal verteilen",
    "Space Vertical":  "Vertikal verteilen",
    "Fill Horizontal": "Horizontal füllen",
    "Fill Vertical": "Vertikal füllen"
    }
  }
