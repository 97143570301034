import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import Dialog from "../mydialog";

import {uploadFile} from "../server";

const resourceMap = {
    "Font": ".ttf, *.ttc",
    "Graphic": "image/*",
    "Data": ".json,.csv,.xlsx,.xls,.xml,.odf",
    };

const UploadDialog = ({dialogOpen, resourceType, value, onCancel, onFileUpload}) => {
    const {t} = useTranslation();
    const inputRef = useRef();
    const [selected, setSelected] = useState(false);
    const [fileName, setFileName] = useState("");
    const [resourceName, setResourceName] = useState("");
    const [resourceDescription, setResourceDescription] = useState("");
    const [file, setFile] = useState(null);

    const handleChangeName = (event) => {
        setResourceName(event.target.value);
        };

    const handleChangeDescription = (event) => {
        setResourceDescription(event.target.value);
        }

    const handleClick = () => {
        inputRef.current.click();
        }

    const handleFileSelected = (event) => {
        console.log("handleFileSelected", event.target.files[0]);
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name)
        setSelected(true);
        }

    const handleFileUpload = async () => {
        console.log("handleFileUpload", file);
        var data = await uploadFile({file, fileName, resourceType, resourceName, resourceDescription} )
        console.log("onFileUpload", data);
        onFileUpload(data);
        }

    return  <Dialog open={dialogOpen} onClose={onCancel} style={{paddingRight: 20}}>
            <DialogTitle>{t("Upload {{resourceType}} Resource", {resourceType: resourceType})}</DialogTitle>
            <DialogContent sx={{margin: 1}}>
                <DialogContentText>
                    {t("Upload a resource to the server.")}
                </DialogContentText>
                <div>
                    <TextField label={t("Resource Name")} value={value} onChange={handleChangeName}
                               sx={{margin: 1, width: 300}}/>
                </div>
                <div>
                    <TextField label={t("Resource Description")} value={value} onChange={handleChangeDescription} multiline={true}
                               sx={{margin: 1, width: 300}}/>
                </div>
                <div>
                    <TextField value={fileName} disabled={true} sx={{margin: 1, width: 300}}/>
                    <Button variant={"contained"} component={"label"} onClick={handleClick}
                            sx={{margin: 1}}>{t("Select")}</Button>
                </div>
                <input ref={inputRef} hidden name={"resourceFile"} accept={resourceMap[resourceType]} type={"file"}
                       onChange={handleFileSelected}/>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} disabled={!selected} onClick={handleFileUpload} startIcon={<UploadIcon/>}>
                    {t("Upload")}
                </Button>
            </DialogActions>
        </Dialog>;
        }
export default UploadDialog;
