import React, {useState, forwardRef} from 'react';

import { IconButton } from "@mui/material";

import MyTooltip from "./mytooltip";

const ToolbarEntry = forwardRef(({name, keyName, iconTrue, iconFalse = null, value, literal=null, color="black", disabled=false, onChange}, ref) => {

    const handleClick = () => {
        if (literal != null) {
            if (value != literal) {
                onChange(keyName, literal);
                }
            } else {
            onChange(keyName, !value);
            }
        }

    iconFalse = iconFalse ?? iconTrue;

    var Icon = iconFalse;

    if (literal != null) {
        if (value == literal) {
            Icon = iconTrue;
            color = "red";
            }
        } else {

        if (value) {
            Icon = iconTrue;
            color = "red";
            }
        }

    if (disabled) {
        color = "lightgrey";
        }

    return <MyTooltip title={name}>
                <IconButton disabled={disabled} onClick={handleClick}>
                    <Icon sx={{color: color}} />
                </IconButton>
            </MyTooltip>;
    })

export const ToolbarSelection = ({name, keyName, iconMapping, value, disabled, onChange}) => {
    const handleClick = () => {
        var keys = [...iconMapping.keys()];
        var index = keys.indexOf(value);
        index = (index + 1) % keys.length;
        onChange(keyName, keys[index]);
        }

    var CurIcon = iconMapping.get(value);

    return <MyTooltip title={name}>
                <IconButton disabled={disabled} onClick={handleClick}>
                    <CurIcon />
                </IconButton>
            </MyTooltip>;
    }

export default ToolbarEntry;
