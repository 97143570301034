import React from "react";
import {MenuItem} from "@mui/material";

import SelectAttribute from "./selectattribute";
import {useLabelEditorCommands} from "../commandsprovider";
import {useTranslation} from "react-i18next";

const SelectFieldType = ({value, onChange}) => {
    const {t} = useTranslation();
    const rules = useLabelEditorCommands();

    var fieldTypes = Object.entries(rules);

    var fieldTypeOptions = fieldTypes.map(([key, value], index) => {
        return <MenuItem key={key} value={key}>{value.nls}</MenuItem>;
        });

    return <SelectAttribute value={value} keyName={"type"} name={t("Field Type")} onChange={onChange}>
               {fieldTypeOptions}
            </SelectAttribute>;
    }

export default SelectFieldType;
