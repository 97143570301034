import React, {useRef} from "react";
import {Image, Rect} from "react-konva";

import useImage from "use-image";

import {getResourceUrl} from "../server";

const BitmapField = ({width, height, form}) => {
    const imageRef = useRef();
    const bitmap = form.bitmap || {value: ""};
    const [img] = useImage(getResourceUrl(bitmap.value));
    return <>
            <Rect scaleX={1} scaleY={1} x={0} y={0}  width={width} height={height} fill={"lightgray"} />
            <Image ref={imageRef} scaleX={1} scaleY={1} x={0} y={0} width={width} height={height} image={img} />
        </>;
    }

export default BitmapField;
