import React, {useState, useContext, useEffect, useMemo} from "react";
import {MenuItem} from "@mui/material";

import {useTranslation} from "react-i18next";

import YesNoAttribute from "./yesnoattribute";
import TextAttribute from "./textattribute";
import SelectAttribute from "./selectattribute";
import MappingAttribute from "./mappingattribute";
import ScriptAttribute from "./scriptattribute";
import ResourceAttribute from "./resourceattribute";

import {useDataStructure} from "../datastructure";

import DynamicMdx from "../help/dynamic.mdx";
import MyTooltip from "./mytooltip";

const DataAttribute = ({value, keyName, name, resourceType, multi=false, userData= null,  onChange}) => {
    const {t, i18n} = useTranslation();

    const dataStructure = useDataStructure();

    const subRecord = useMemo(() => value ?? {  prompt: "", xPath:"", sample: "", dynamic: false, value: "", dataMapping: false, min: "", max: "", dataScriptSelect: false, dataScript: "", fieldType: "text", mapping: []}, [value]);

    //const [subRecord, setSubRecord] = useState(value || {  prompt: "", xPath:"", sample: "", dynamic: false, value: "", dataMapping: false, min: "", max: "", dataScriptSelect: false, dataScript: "", fieldType: "text", mapping: []});

     const handleChange = (key, value) => {

        var newSubRecord = structuredClone(subRecord);
        newSubRecord[key] = value;

        onChange(keyName, newSubRecord, userData);
        }

    //useEffect(() => {
    //
    //    if (value != subRecord) {
    //        if (value) {
    //            setSubRecord(value);
    //            }
    //        }
    //    }, [value, subRecord]);

    var {dynamic, dataScriptSelect, dataScript, dataMapping, xPath, prompt} = subRecord;

    var subValue = subRecord.value;
    var sampleOptions = [];

    var xPathOptions = dataStructure.values.map((item) => {
        var [name, dataOptions] = item;
        if (name == xPath) {
            sampleOptions = dataOptions.map((sampleItem) => {
                return {label: sampleItem.toString(), id: sampleItem.toString()};
                });
            }
        return {label: name, id:name};
        });

    var resourceCol = null;

    resourceField = [];

    if (resourceType) {
            if (resourceType == "Design") {
                var withUpload = false;
            } else {
                var withUpload = true;
            }
            var resourceField = <ResourceAttribute value={subRecord.value} keyName={"value"} name={"Default"}
                                                   resourceType={resourceType} withUpload={withUpload}
                                                   userData={userData} onChange={handleChange} variant={"toolbar"}/>;
            resourceCol = 1;
        } else {
            var resourceField = <TextAttribute value={subRecord.value} keyName={"value"} name={"Value"} multi={multi}
                                               userData={userData} onChange={handleChange}/>;
        }

    return <div style={{paddingTop: 20, paddingRight: 10}}>

                <fieldset style={{paddingTop: 10}}>
                 <legend style={{fontFamily: "Roboto"}}>{name}</legend>

                <span>
                    {resourceField}

                    <YesNoAttribute value={dynamic} keyName={"dynamic"} name={t("Dynamic")} userData={userData} HelpMdx={DynamicMdx} onChange={handleChange} />
                </span>

                {dynamic &&
                    <>
                        <div>
                            <TextAttribute value={prompt} keyName={"prompt"} name={t("Prompt")} userData={userData}
                                               tooltip={t("If set, field data will be prompted during label creation, using the prompt name entered here")} onChange={handleChange} />

                            <SelectAttribute value={subRecord.fieldType} keyName={"fieldType"} name={t("Field Type")} userData={userData} onChange={handleChange}>
                                <MenuItem value={"TEXT"}>{t("Text")}</MenuItem>
                                <MenuItem value={"NUMBER"}>{t("Number")}</MenuItem>
                                <MenuItem value={"DATE"}>{t("Date")}</MenuItem>
                                <MenuItem value={"READONLY"}>{t("Read Only")}</MenuItem>
                                <MenuItem value={"HIDDEN"}>{t("No Show")}</MenuItem>
                            </SelectAttribute>

                        </div>

                        <div>
                            <TextAttribute value={subRecord.xPath} keyName={"xPath"} name={t("XPath")} options={xPathOptions} userData={userData} onChange={handleChange}
                                            tooltip={t("Path to field content when using external data (like XML or Excel file)")}/>


                            <TextAttribute value={subRecord.sample} keyName={"sample"} name={t("Sample")} options={sampleOptions} multi={multi} userData={userData} onChange={handleChange}
                                            tooltip={t("Sample value to use for preview in layout editor")} />
                        </div>

                    {subRecord.fieldType == "NUMBER" &&
                        <>
                            <TextAttribute value={subRecord.min} keyName={"min"} name={t("Minimum")} userData={userData} onChange={handleChange} />
                            <TextAttribute value={subRecord.max} keyName={"max"} name={t("Maximum")} userData={userData} onChange={handleChange} />
                        </>
                        }

                    {subRecord.fieldType == "DATE" &&
                        <>
                            <TextAttribute value={subRecord.min} keyName={"min"} name={t("Minimum")} userData={userData} onChange={handleChange} />
                            <TextAttribute value={subRecord.max} keyName={"max"} name={t("Maximum")} userData={userData}  onChange={handleChange} />
                        </>
                        }
                     <YesNoAttribute value={dataMapping} keyName={"dataMapping"} name={t("Mapping")} userData={userData} onChange={handleChange} />

                    {dataMapping &&
                        <MappingAttribute value={subRecord.mapping} keyName={"mapping"} resourceType={resourceType} withUpload={{withUpload}} resourceCol={resourceCol} userData={userData} onChange={handleChange} />
                        }

                    <YesNoAttribute value={dataScriptSelect} keyName={"dataScriptSelect"} name={t("Script")} userData={userData} onChange={handleChange} />

                    {dataScriptSelect &&
                        <ScriptAttribute mode={"python"} theme={"github"} value={subRecord.dataScript} keyName={"dataScript"} userDate={userData} onChange={handleChange} />
                        }

                    </>
                }
            </fieldset>
        </div>;
    }

export default DataAttribute;
