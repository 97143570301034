import React from "react";

import {Button, Typography, Table, TableHead, TableBody, TableRow, TableCell, IconButton, TextInput }from "@mui/material";

import {useTranslation} from "react-i18next";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import TextAttribute from "./textattribute";
import ResourceAttribute from "./resourceattribute";
import MappingLine from "./mappingline";

const MappingAttribute = ({value=[], keyName, resourceType, withUpload, resourceCol, userData=null, onChange}) => {
    const {t} = useTranslation();

    const handleAdd = () => {
         var newRecord = structuredClone(value);
         newRecord.push(["", ""]);
         onChange(keyName, newRecord, userData);
        }

    const handleDelete = (index) => {
        var newRecord = structuredClone(value);
        newRecord.splice(index, 1);
        onChange(keyName, newRecord, userData);
        }

    const handleChange = (index, newValue) =>  {
        var newRecord = structuredClone(value);
        newRecord[index] = newValue;
        onChange(keyName, newRecord, userData);
        }

    var lines = value.map((entry, index) => {
        return <MappingLine value={entry}
                            keyName={keyName}
                            key={index}
                            index={index}
                            resourceType={resourceType}
                            withUpload={withUpload}
                            resourceCol={resourceCol}
                            onDelete={handleDelete}
                            onChange={handleChange} />;
        });

    return <>
            <Table size={"lg"}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("Value")}</TableCell>
                        <TableCell size={"lg"}>{t("Selection")}</TableCell>
                        <TableCell>{t("Action")}<IconButton onClick={handleAdd}><AddIcon /></IconButton></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lines}
                </TableBody>
            </Table>
        </>;
    }

export default MappingAttribute;
