export const requirePrompt = (rule, value) => {
        if (rule.class) {
            if (!rule.class.split(" ").includes("hasPrompt")) {
                rule = {...rule};
                rule.type = "hidden";
                }
            }
        return rule;
        }
export const requireNoXpathAndPrompt = (rule, value) => {
        if (rule.datepicker) {
            return rule;
            }
        if (rule.class) {
            if (!rule.class.split(" ").includes("hasPrompt")) {
                rule = {...rule};
                rule.type = "hidden";
                }
            if (rule.class.split(" ").includes("hasXPath")) {
                rule = {...rule};
                rule.type = "hidden";
                }

            } else {
            rule = {...rule};
            rule.type = "hidden";
            }
        return rule;
        }
