import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {
    AppBar,
    Box,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    Paper,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import Drawer from "../mydrawer";
import ErrorBoundary from "../errorboundary";
import {hoverMenu} from "../styles";
import ActionButton from "./actionbutton";
import parentRequest from "../parentcomm";

import Layout from "./layout";
import Output from "./output";

const Sidebar = ({fullScreenHandle, initRsp}) => {
    const {t} = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [mode, setMode] = useState("layout")

    const handleFullscreen = () => {
        fullScreenHandle.enter();
        }

    const toggleMenu =  () => {
        setMenuOpen((oldState) => !oldState);
        }

    const handleTestParent = async() => {
        var rsp = await parentRequest({type: "Test"});
        console.log("Test Parent", rsp);
        }

    const handleSignout = () => {
        console.log("handleSignout");
        }

    const handleHelp = () => {
        console.log("handleHelp");
        }

    const handleLayout = () => {
        setMode("layout");
        }

    const handleOutput = () => {
        setMode("output");
        }

    const handleRate = () => {
        console.log("handleRate");
        }

    const extensions = {
         buttonsTitle: "Select Spreadsheet Data and Print",
            buttons: {
                printAll: { name: "Print All"},
                printSelected:{ name: "Print Selected"}
                },
            optionsTitle: "Options",
            options: {
                labelPerColumn: {name:"Label per column", checked:getBoolDocumentProperty("verticalLabels")},
                noHeader: {name:"No header column or row", checked:getBoolDocumentProperty("noHeaders")}
                }
            };

    return <>
            <AppBar position={"static"}>
                <Toolbar>
                    <IconButton color={"inherit"} aria-label={t("Menu")} onClick={toggleMenu}>
                        <MenuIcon />
                    </IconButton>
                    <IconButton color="inherit" aria-label={t("Fullscreen")} onClick={handleFullscreen}>
                        <FullscreenIcon />
                    </IconButton>
                    <Typography>{t("Next Generation Label Printing System Sidebar")}</Typography>
                </Toolbar>
            </AppBar>

            <Drawer anchor={"left"} variant="temporary" open={menuOpen} onClose={toggleMenu}>
                <ErrorBoundary>
                     <Box sx={{display:"flex", flexDirection:"column", height:"100%"}} role={"presentation"}>
                         <ListItem>
                            <ListItemButton onClick={toggleMenu} sx={hoverMenu}>{t("Menu")}</ListItemButton>
                         </ListItem>
                         <Divider />
                         <ListItem>
                             <ListItemButton onClick={handleTestParent} sx={hoverMenu}>{t("Test Parent")}</ListItemButton>
                         </ListItem>
                     </Box>
                </ErrorBoundary>
            </Drawer>

        <Paper>

            <Stack direction={"column"} >

                <Stack direction={"row"}>
                    <ActionButton disabled={mode=="layout"} onClick={handleLayout} label={t("Layout")} />
                    <ActionButton disabled={mode=="output"} onClick={handleOutput} label={t("Output")} />
                    <ActionButton onClick={handleHelp} label={t("Help")} />
                    <ActionButton onClick={handleRate}label={t("Rate")} />
                </Stack>

                {mode == "layout" &&
                    <Layout extensions={extensions}/>
                    }

                {mode == "output" &&
                    <Output />
                    }

                <img alt={"RSJ Logo"}/>
                <a href={"https://lpsng.rsj.de"} target={"_blank"}>{t("RSJ Next Generation Label Printing System")}</a>
                <Stack direction={"row"}>
                    <a onClick={handleSignout}>{t("Sign Out")}</a>
                    <a onClick={handleHelp}>{t("Help")}</a>
                </Stack>
            </Stack>
        </Paper>
    </>;
}
export default Sidebar;
