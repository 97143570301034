import React, {useState, createContext, useContext, useEffect} from "react";

import {IconButton, Drawer, Box, Button} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import {useTranslation} from "react-i18next";

import MyTooltip from "./mytooltip";

const HelpContext = createContext(null);

const Help = ({HelpMdx}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [Mdx, setMdx] = useState(HelpMdx);
    const handleClick = () => {
        setOpen(!open);
        setMdx(HelpMdx);
        }

    const handleClose = () => {
        setOpen(false);
        setMdx(HelpMdx);
        }

    const handleLink = (NextHelpMdx) => {
        setMdx(NextHelpMdx);
        }

    useEffect(() => {
        if (Mdx != HelpMdx) {
            setMdx(HelpMdx);
            }
        }, [HelpMdx]);

    return <>
                <MyTooltip title={t("Help")}>
                    <IconButton onClick={handleClick}><HelpIcon /></IconButton>
                </MyTooltip>

                <Drawer anchor={"right"} variant={"persistent"} open={open} onClose={handleClose}>
                    <Box sx={{display:"flex", flexDirection:"column", height:"100%", fontFamily:"Arial,Helvetica", margin: 2}}>
                        <HelpContext.Provider value={{handleLink}}>
                            {Mdx}
                        </HelpContext.Provider>
                    </Box>
                </Drawer>

            </>

    }

export const HelpLink = ({HelpMdx}) => {
    const {handleLink} = useContext(HelpContext);

    const handleClick = (event) => {
        handleLink(HelpMdx);
        event.preventDefault();
        }
   return <Button onClick={handleClick}>Link</Button>
   }

export default Help;
