import React, {useState, createContext, useContext, useEffect, StrictMode, useRef} from "react";

import {useSignal} from "@preact/signals-react";

import {Paper, AppBar, Toolbar, IconButton, Divider, Typography, Box, List, ListItem, ListItemButton, ListItemText, Badge, Menu, Avatar, Tooltip} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon  from "@mui/icons-material/AccountCircle";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import {Provider} from "jotai";

import {useTranslation, I18nextProvider, initReactI18next} from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {FullScreen, useFullScreenHandle} from "react-full-screen";

import Drawer from "./mydrawer";

import DesignListProvider from "./designlistprovider";
import FontLoader from "./layouteditor/fontloader";
import LabelEditorCommandsProvider from "./commandsprovider";
import LabelProvider, {useSetLabelId, useSetReferenceLabelId} from "./labelprovider";
import DataStructureProvider from "./datastructure";
import FullscreenProvider from "./fullscreenprovider";

import {UserInfoProvider, useUserInfo} from "./userinfoprovider";
import useConfigValue from  "./configprovider";

import About from "./about";
import LabelEditor from "./labeleditor";

import {TestSignal} from "./signals";

import {MyRouter, MyRoute, MyRouterMenuItem, MyMenuItem, useSetPath} from "./myrouter";
import ErrorBoundary from "./errorboundary";

import JobList from "./joblist";
import DesignList from "./designlist";
import DisplayPdf from "./displaypdf";
import Master from "./master";

import {getLoginUrl, getLogoutUrl, getAvatarUrl} from "./server";

import parentRequest from "./parentcomm";

import {hoverMenu} from "./styles";

import de from "./locales/de.json";
import en from "./locales/en.json";

import Sidebar from "./sidebar";

var missingKeys = {};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init( {
        fallbackLng: "en",
        resources: {
            en: en,
            de: de
            },
        saveMissing: true,
        missingKeyHandler: (lngs, ns, key, fallbackValue) => {
            if (missingKeys[key] == undefined) {
                missingKeys[key] = key;
                console.log("Missing key: lang:"+lngs + " key:" +key + " in namespace: " + ns );
                }
            return fallbackValue;
            }
        });

const App = () => {
    const inFrame = (window.parent != window);

    return <StrictMode>
                <ErrorBoundary>
                    <Provider>
                        <I18nextProvider i18n={i18n}>
                            <AppProviders>
                                {inFrame &&
                                    <FrameApp />}
                                {!inFrame &&
                                    <Main />}
                            </AppProviders>
                        </I18nextProvider>
                    </Provider>
                </ErrorBoundary>
        </StrictMode>;
    }

const FrameApp = () => {
    const [frameMode, setFrameMode] = useState("");
    const [initRsp, setInitRsp] = useState({});

    useEffect(() => {
        const fetchMode = async () => {
            var rsp = await parentRequest({op: "init"});
            setInitRsp(rsp);
            setFrameMode(rsp.mode);
            }

        fetchMode();
        }, []);

    return <>
        {frameMode == "sidebar" &&
            <SidebarApp initRsp={initRsp}/>
            }

        {frameMode == "modal" &&
            <ModalApp initRsp={initRsp}/>
          }
      </>;

    }
const SidebarApp = () => {
    const fullScreenHandle = useFullScreenHandle();
    const containerRef = useRef();

    return <>
            <Sidebar fullScreenHandle={fullScreenHandle}/>

            <FullScreen handle={fullScreenHandle}>

                {fullScreenHandle.active &&
                    <>
                        <FullscreenProvider container={containerRef}>
                            <Main fullScreenHandle={fullScreenHandle}/>
                        </FullscreenProvider>

                        <div ref={containerRef}/>
                    </>
                }
            </FullScreen>
        </>;
    }

const ModalApp = () => {
    return <AppBar position={"static"}>
                <Toolbar>
                    <Typography>{t("Next Generation Label Printing System Modal")}</Typography>
                </Toolbar>
            </AppBar>;
    }
const Main = ({fullScreenHandle}) => {
    const {t, i18n} = useTranslation();

    const [menuOpen, setMenuOpen] = useState(false);
    const [userMenuOpen, setUserMenuOpen] = useState(false);

    const [printOpen, setPrintOpen] = useState(false);
    const [curParms, setCurParms] = useState({});

    const setLabelId = useSetLabelId();
    const [pdfId, setPdfId] = useState(null);

    const testSignal = useSignal(TestSignal);

    const userInfo = useUserInfo();

    const setPath = useSetPath();

    const config = useConfigValue();
    const setReferenceLabelId = useSetReferenceLabelId();

    const toggleMenu = (event) => {
        setMenuOpen(!menuOpen);
        }

    const toggleUserMenu= (event) => {
        setUserMenuOpen(!userMenuOpen);
        }

    const togglePrint = () => {
        setMenuOpen(false);
        setPrintOpen(!printOpen);
        }

    const handleSelect = (newPath) => {
        setMenuOpen(false);
        setUserMenuOpen(false);
        }

    const handleChange = (key, newValue) => {
        if (newValue != curParms) {
            setCurParms(newValue);
            }
        }

    const handleOpenEditor = (id) => {
        setLabelId(id);
        setPath("/");
        }

    const handleOpenPDF = (id) => {
        setPdfId(id);
        setPath("/display")
        }

    const handleSetEnglish = () => {
        i18n.changeLanguage("en");
        }

    const handleSetGerman = () => {
        i18n.changeLanguage("de");
        }

    const handleLogin = () => {
        toggleUserMenu();
        var url = getLoginUrl();
        window.open(url, "_blank", 'top=0,left=0,width=1000,height=800,popup');
        }

    const handleLogout = () => {
        toggleUserMenu();
        const url = getLogoutUrl();
        window.open(url, "_blank", 'top=0,left=0,width=1000,height=800, popup');
        }

    const handleFullscreenExit = () => {
        fullScreenHandle.exit();
        }

    const handleDumpTranslations = () => {
        console.log(missingKeys);
        }

    useEffect(() => {
        if (config.designId) {
            setPath("/editor/"+config.designId);
            } else {
            setPath("/designlist");
            }

        if (config.referenceId) {
            setReferenceLabelId(config.referenceId);
            }
        }, []);

    var curUser = null;

    if (userInfo) {
        curUser = userInfo.nickName
        }

    return  <MyRouter initialPath={"/"} onSelect={handleSelect}>
                <Paper>
                    <Box sx={{flexGrow:1}}>

                        <AppBar position="static">
                            <Toolbar>
                                <IconButton color="inherit" aria-label={t("Menu")} onClick={toggleMenu}>
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant={"h6"} style={{flexGrow:1}} color="inherit">{t("Next Generation Label Printing")} {testSignal}</Typography>

                                {fullScreenHandle &&
                                    <IconButton color={"inherit"} onClick={handleFullscreenExit} aria-label={t("Exit Fullscreen")}>
                                        <FullscreenExitIcon />
                                    </IconButton>
                                    }

                                <IconButton color="inherit" onClick={toggleUserMenu} aria_label={t("Account")}>
                                    {curUser == null &&
                                        <AccountCircleIcon />
                                        }

                                    {curUser != null &&
                                        <Tooltip title={curUser}>
                                            <Avatar alt={curUser} src={getAvatarUrl(userInfo.emailHash)} />
                                        </Tooltip>
                                        }

                                </IconButton>
                            </Toolbar>
                        </AppBar>

                    <Drawer anchor={"left"} variant="temporary" open={menuOpen} onClose={toggleMenu}>
                        <ErrorBoundary>
                         <Box sx={{display:"flex", flexDirection:"column", height:"100%"}} role={"presentation"}>
                             <ListItem>
                                <ListItemButton onClick={toggleMenu} sx={hoverMenu}>{t("Menu")}</ListItemButton>
                             </ListItem>
                             <Divider />
                             <MyRouterMenuItem path={"/editor"} label={t("Label Editor")} />
                             <MyRouterMenuItem path={"/joblist"} label={t("Job List")} />
                             {!config.continueUrl &&
                                <>
                                <MyRouterMenuItem path={"/designlist"} label={t("Design List")} />
                                <MyRouterMenuItem path={"/master"} label={t("Master")} />
                                </>
                                }
                            <Divider>
                                <MyMenuItem label={t("Dump translations")} onClick={handleDumpTranslations} />
                            </Divider>
                         </Box>
                        </ErrorBoundary>
                    </Drawer>

                    <Drawer anchor={"right"} variant="temporary" open={userMenuOpen} onClose={toggleUserMenu}>
                            <Box sx={{display:"flex", flexDirection:"column", height:"100%", listItem: {':hover': {backgroundColor: 'blue', color: "white"}}}}>
                                <ErrorBoundary>
                                    <List>

                                        <ListItem>
                                            <ListItemButton onClick={toggleUserMenu} sx={hoverMenu}>
                                                <ListItemText primary={t("Menu")}  />
                                            </ListItemButton>
                                        </ListItem>

                                        <Divider />

                                        {!config.continueUrl &&
                                            <>
                                                <ListItem>
                                                    <ListItemButton onClick={handleLogin} disabled={curUser != null} sx={hoverMenu}>{t("Login")} </ListItemButton>
                                                </ListItem>

                                                <ListItem>
                                                    <ListItemButton onClick={handleLogout}  disabled={curUser == null} sx={hoverMenu}>{t("Logout")} </ListItemButton>
                                                </ListItem>

                                                <Divider />
                                                </>
                                            }
                                        <ListItem>
                                            <ListItemButton onClick={handleSetEnglish} sx={hoverMenu}>English</ListItemButton>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemButton onClick={handleSetGerman} sx={hoverMenu}>Deutsch</ListItemButton>
                                        </ListItem>
                                        <Divider />

                                        <MyRouterMenuItem path={"/about"} label={t("About")} />
                                    </List>

                                </ErrorBoundary>
                            </Box>
                    </Drawer>
                    </Box>
                </Paper>

                <Paper>
                    <MyRoute path={"/editor"}  Component={LabelEditor} />
                    <MyRoute path={"/joblist"} Component={JobList} />
                    <MyRoute path={"/designlist"} Component={DesignList} />
                    <MyRoute path={"/display"} Component={DisplayPdf} />
                    <MyRoute path={"/about"} Component={About } />
                    <MyRoute path={"/master"} Component={Master} />
                </Paper>

            </MyRouter>;
    }

const AppProviders = ({children}) => {
    return <FullscreenProvider>
            <FontLoader>
                <UserInfoProvider>
                    <LabelEditorCommandsProvider>
                        <DesignListProvider>
                            <LabelProvider>
                                <DataStructureProvider>
                                    {children}
                            </DataStructureProvider>
                            </LabelProvider>
                        </DesignListProvider>
                    </LabelEditorCommandsProvider>
                </UserInfoProvider>
            </FontLoader>
        </FullscreenProvider>;
    }

export default App;
