import React, {useState, useEffect} from "react";
import {Stack, Button} from "@mui/material";

import {useTranslation} from "react-i18next";

import PdfViewer from "./pdfviewer";
import Dform from "./attributeeditor/dform";
import {getMaster, getLabelUrl, getInputForm, storeMaster} from "./server";
import {requireNoXpathAndPrompt} from "./formextension";

const buttonStyle = {margin: 1}

const Master = ({parms}) => {
    const {t} = useTranslation();
    const [selectData, setSelectData] = useState({});
    const [storeData, setStoreData] = useState({});
    const [selectRules, setSelectRules] = useState([]);
    const [storeRules, setStoreRules] = useState([]);
    const [labelUrl, setLabelUrl] = useState("");
    const [masterId, setMasterId] = useState("");
    const handleSelectChange = (key, value) => {
        setSelectData((oldValue) => {
            var newValue = structuredClone(oldValue);
            if (key) {
                newValue[key] = value;
                } else {
                newValue = {...value};
                }
            return newValue;
            });
        }

    const handleStoreChange = (key, value) => {
        setStoreData((oldValue) => {
            var newValue = structuredClone(oldValue);
            if (key) {
                newValue[key] = value;
                 } else {
                newValue = {...value};
                }
            return newValue;
            });
        }

    const handleReset = () => {
        setSelectData({});
        setStoreData({});
        setStoreRules([]);
        }

    const handlePreview = () => {
        var newUrl = getLabelUrl({...selectData, ...storeData});
        setLabelUrl(newUrl);
        }

    const handleStore = () => {
        storeMaster(storeData);
        handlePreview();
        }

    useEffect(() => {
        let valid = true;
        const fetchData = async() => {
            var data = await getMaster(masterId);
            if (valid) {
                setSelectData({});
                setSelectRules((data?.select?.elements) ?? []);
                }
            }

        fetchData();
        return () => {valid = false;}
        }, [masterId]);

    useEffect(() => {
         let valid = true;

        const fetchData = async () => {
            var data = await getInputForm(selectData);
            if (valid) {
                if (data.elements) {
                    setStoreRules(data.elements);
                   }
                }
            }

        if (Object.keys(selectData).length > 0) {
            fetchData();
            }

        return () => {valid = false};

        }, [selectData]);

    var newMasterId = parms.substring(1);
    if (newMasterId != masterId) {
        setMasterId(newMasterId);
        }

    return <>
            <Stack direction={"row"}>
                <PdfViewer url={labelUrl} withControl={true} />
                <Stack direction={"column"}>
                    <p></p>
                    <Dform rules={selectRules} value={selectData} onChange={handleSelectChange}/>
                    {storeRules &&
                        <Dform rules={storeRules} value={storeData} preProc={requireNoXpathAndPrompt} onChange={handleStoreChange} />
                        }
                    <Stack direction={"row"}>
                        <Button variant={"contained"} sx={buttonStyle} onClick={handleReset}>{t("Reset")}</Button>
                        <Button variant={"contained"} sx={buttonStyle} onClick={handlePreview}>{t("Preview")}</Button>
                        <Button variant={"contained"} sx={buttonStyle} onClick={handleStore}>{t("Store")}</Button>
                    </Stack>
                </Stack>
            </Stack>
            </>;
    }

export default Master;
