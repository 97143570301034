import React, {useState, useRef} from "react";

import {
    IconButton,
    MenuItem
} from "@mui/material";

import Select from "../myselect";

import UploadIcon from "@mui/icons-material/Upload";

import {useTranslation} from "react-i18next";

import SelectAttribute from "./selectattribute";

import {useDesignList, useDesignListRefresh} from "../designlistprovider";

import UploadDialog from "./uploaddialog";

import MyTooltip from "./mytooltip";

const resourceMap = {
    "Font": ".ttf, *.ttc",
    "Graphic": "image/*",
    "Data": ".json,.csv,.xlsx,.xls,.xml,.odf",
    }

const ResourceAttribute = ({value, name, keyName, resourceType, withUpload=true,  variant="dialog", userData=null, onChange}) => {

    const {t} = useTranslation();

    const resources = useDesignList();
    const refreshResources = useDesignListRefresh();

    const [uploadOpen, setUploadOpen] = useState(false);
    const [fileName, setFileName] = useState("");

    const inputRef = useRef();

    const [additionalResource, setAdditionalResource] =  useState(null);

    const handleChange = (event) => {
        var {newValue} = event.target;
        onChange(keyName, newValue, userData);
        }

    const handleSelectChange = (event) => {

        var newValue = event.target.value;
        onChange(keyName, newValue, userData);
        }
    const cancelUpload = () => {
        setUploadOpen(false);
        }

    const handleUpload = () =>{
        setUploadOpen(true);
        }

    const handleFileUpload = (newId) => {
        refreshResources();
        window.setTimeout(() => {
            onChange(keyName, newId, userData);
            }, 1000);
        setUploadOpen(false);
        }

    const handleClick = () => {
        inputRef.current.click();
        }

    var preview = null;

    var ids = [""];

    var options = resources.filter((resource) => resource.type == resourceType)
        .map((resource) => {
            if (resource.id == value) {
                preview = resource.preview;
                }
            ids.push(resource.id);
            return <MenuItem key={resource.id} value={resource.id}>{resource.name}</MenuItem>
            });

    if (value != null && !ids.includes(value) && additionalResource != value) {
        setAdditionalResource(value);
        }

    if (additionalResource != null) {
        options.push(<MenuItem key={additionalResource} value={additionalResource}>{additionalResource}</MenuItem>);
       }

    options.push(<MenuItem key={""} value={""}>{t("None")}</MenuItem>);

    if (variant == "toolbar") {
        return  <>
                    <span>
                        <MyTooltip title={name}>
                            <Select value={value} onChange={handleSelectChange} sx={{width: "20em"}}>{options}</Select>
                        </MyTooltip>
                    {withUpload &&
                        <MyTooltip title={t("Upload {{resourceType}}", {resourceType: resourceType})}>
                            <IconButton onClick={handleUpload} color={"secondary"}><UploadIcon /></IconButton>
                        </MyTooltip>
                    }
                    </span>
                {withUpload &&
                    <UploadDialog dialogOpen={uploadOpen} onCancel={cancelUpload} resourceType={resourceType} onFileUpload={handleFileUpload}/>
                    }
                </>
          }


    return <>
            <div>
                <SelectAttribute keyName={keyName} name={name} value={value} options={options} userData={userData} onChange={onChange}>
                    {options}
                </SelectAttribute>

                {preview != null &&
                    <img src={preview} style={{width: 100}}/>
                    }

                {withUpload &&
                    <>
                         <MyTooltip title={t("Upload {{resourceType}}", {resourceType: resourceType})}>
                            <IconButton onClick={handleUpload} color={"secondary"}><UploadIcon /></IconButton>
                         </MyTooltip>
                        <UploadDialog dialogOpen={uploadOpen} onCancel={cancelUpload} resourceType={resourceType} onFileUplad={handleFileUpload}/>
                    </>
                    }
            </div>
           </>
    }

export default ResourceAttribute;
