var callActive = false;
var callQueue = [];

const issueRequest = async (reqString) => {

    return new Promise((resolve, reject) => {
        window.addEventListener("message", (event) => {
            if (event.source == window.parent) {
                resolve(event.data);
                }
            });
        window.parent.postMessage(reqString);
        });
    }
const startNext = async () => {
    if (!callActive) {
        if (callQueue.length == 0) {
            return
            }
        var [resolve, parms] = callQueue.pop();
        callActive = true;
        var rsp = await issueRequest(JSON.stringify(parms))
        rsp = JSON.parse(rsp);
        resolve(rsp);
        callActive = false;
        startNext();
        }
    }

const parentRequest = async (parms) => {
    var ret = new Promise((resolve, reject) => {
        callQueue.push([resolve, parms]);
        startNext();
        });
    return ret;
    }

export default parentRequest;
