import React, {useState} from "react";

import PdfViewer from "./pdfviewer";

import {getDownloadUrl} from "./server";

const DisplayPdf = ({parms}) => {

    const src = getDownloadUrl(parms.substring(1));

    return <PdfViewer url={src} withControl={true}/>;
    }

export default DisplayPdf;
