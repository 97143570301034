import React, {useState, useEffect, useReducer} from "react";

import {isEqual} from "lodash";

import TabsElement from "./tabselement";

import SelectElement from "./selectelement";
import DatafieldElement from "./datafieldelement";
import TextElement from "./textelement";
import NumberElement from "./numberelement";
import DivElement from "./divelement";
import FieldsetElement from "./fieldsetelement";
import TextareaElement from "./textareaelement";
import ResourceFieldElement from "./resourcefieldelement";
import HiddenElement from "./hiddenelement";


import SelectAttribute from "./selectattribute";

const elementMap = {
    tabs: TabsElement,
    select: SelectElement,
    dataField: DatafieldElement,
    text: TextElement,
    number: NumberElement,
    div: DivElement,
    fieldset: FieldsetElement,
    textarea: TextareaElement,
    ResourceField: ResourceFieldElement,
    hidden: HiddenElement
    };


const initialize = (rules, value) => {
    var containsInput = false;

    if (typeof(rules) == "string") {
        return;
        }
    if (typeof(rules) == "number") {
        return;
        }

    if (typeof(rules) == "object") {
        if (rules.name != undefined && rules.value != undefined) {
            if (value[rules.name] == undefined) {

                value[rules.name] = rules.value;
                }
            }

        var wrkRules = Object.entries(rules);

        wrkRules.forEach (([key, thisVal]) => {
            if ( key == "type") {
                if (["select", "dataField", "text", "number", "textarea", "ResourceField"].includes(thisVal)) {
                    containsInput = true;
                    }
                }
            containsInput |= initialize(thisVal, value);
            });

        } else {
        if (Array.isArray(rules)) {
            rules.forEach((thisVal) => {
                containsInput |= initialize(thisVal, value);
                });
            }
        }
    return containsInput;
    }


const  Dform = ({value, keyName, rules, span=false, preProc=null, postProc=null, initRules=[], onChange, onNoInput}) => {

    const reducer = (state, action) => {
        //console.log("reducer", state, action);

        const [key, newValue, userData] = action;

        if (state == undefined) {
            state = {};
            }

        if (key == undefined) {

            newRecord = postProc?.call(this, state, newValue, userData) ?? newRecord;

            if (newValue != state) {
                onChange(keyName, newValue, userData);
                }

            return newValue;
            }

        if (state[key] != newValue) {
            var newRecord = structuredClone(state);
            newRecord[key] = newValue;
            newRecord = postProc?.call(this, state, newRecord, userData) ?? newRecord;

            onChange(keyName, newRecord, userData);

            }
        return newRecord;
        }


    const [curValue, setCurValue] = useReducer(reducer, value);

    const handleChange = (key, newValue, userData) => {
        //console.log("Dform.handleChange", key, newValue);

        if (key == undefined) {
            if (!isEqual(newValue, curValue)) {
                setCurValue([key, newValue, userData]);
                }
            } else {
            if (curValue[key] != newValue) {
                setCurValue([key, newValue, userData]);
                }
            }
        }

    const [curValue2, setCurValue2] = useState(value);

    const handleChange2 = (key, newValue, userData) => {
        //console.log("Dform.handleChange2", key, newValue);
        if (key == undefined) {
            if (newValue != curValue2) {
                newValue = postProc?.call(this, curValue2, newValue, userData) ?? newValue;
                setCurValue2(newValue);
                onChange(keyName, newValue, userData)
                }
            } else {
            if (curValue2[key] != newValue) {
                setCurValue2((oldRecord) => {
                    var newRecord = structuredClone(oldRecord);
                    newRecord[key] = newValue;
                    newRecord = postProc?.call(this, oldRecord, newRecord, userData) ?? newRecord;
                    onChange(keyName, newRecord, userData);
                    return newRecord;
                    });
                }
            }
        }

    useEffect(() => {
        if (top) {
            var newValue = structuredClone(value);
            var containsInput = initialize([...rules, ...initRules], newValue);
            //setCurValue([undefined, newValue, undefined]);
            setCurValue2(newValue);
            onChange(keyName, newValue, null);
            if (!containsInput) {
                onNoInput?.call(this);
                }
            }
        }, []);

    if (!isEqual(value, curValue2)) {
        setCurValue2(value);
        }

    return <DformViewer rules={rules} value={curValue2} span={span} preProc={preProc} onChange={handleChange2} />;
    }

export const DformViewer = ({rules, value, span, preProc,  onChange}) => {

    if (!Array.isArray(rules)) {
        rules = [rules];
        }

    var elements = rules.map((rule, index) => {

        if (rule == null) {
            return;
            }
        rule = preProc?.call(this, rule, value) ?? rule;

        var ElementType = elementMap[rule["type"]];
        if (ElementType) {
            var nextVal = value;
            try {
                if (rule?.name) {
                    var nextVal = value[rule.name];
                    }
            } catch (e) {}

            if (span) {
                return <ElementType key={rule.id ?? rule.name ?? index}
                            rule={rule}
                            keyName={rule.name}
                            value={nextVal}
                            preProc={preProc}
                            onChange={onChange} />;
                }

            return <div key={rule.id ?? rule.name ?? index}>

                    <ElementType key={rule.id ?? rule.name?? index}
                                rule={rule}
                                keyName={rule.name}
                                value={nextVal}
                                preProc={preProc}
                                postProc={null}
                                onChange={onChange}/>
                    </div>;
            }
        });
    return elements;
    }

export default Dform;
