import React from "react";

import TextAttribute from "./textattribute";
import ScriptAttribute from "./scriptattribute";

const TextareaElement = ({value, rule, keyName, onChange}) => {

    if (rule.class == "scriptEditorField") {
        return <ScriptAttribute mode={"python"} theme={"github"} value={value} keyName={keyName} userData={rule} onChange={onChange} />;
        }

    return <TextAttribute name={rule.caption} value={value} keyName={keyName} multi={true} userData={rule} onChange={onChange}  />;
    }

export default TextareaElement;
