import React, {Component} from "react";

import {Button, Typography }from "@mui/material";

class ErrorBoundary extends Component {

    static defaultProps= {
        title: ""
        };

    constructor (props, context) {
        super(props, context);
        this.state={error: null, info:null};
        };

    componentDidCatch = (error, info)=>{
        console.log(error);
        console.log(info);
        this.setState({error: error, info: info});
        };

    resetError = ()=>{
        this.setState({error:null, info: null});
        };

    render = () => {
        if (this.state.error) {
            return <>
                        <div>
                            <Typography>Error {this.props.title}</Typography>
                        </div>
                        <div>
                            <Typography>{this.state.error.toString()}</Typography>
                        </div>
                        <div>
                            <Typography>{this.state.info.toString()}</Typography>
                        </div>
                        <div>
                            <Button variant="contained" onClick={this.resetError}>Reset</Button>
                        </div>
                    </>;
            }
        return this.props.children;
        };
    };

export default ErrorBoundary;
