import React from "react";

import DataAttribute from "./dataattribute";

const DatafieldElement = ({value, keyName, rule, onChange}) => {
    const multi = rule.class.split(" ").includes("multi");
    return <DataAttribute value={value} keyName={keyName} name={rule.fieldCaption} multi={multi} userData={rule} onChange={onChange}/>;
    }

export default DatafieldElement;
