import React, {Component, useState, useRef, useEffect, Suspense} from "react";

import {useTranslation} from "react-i18next";

import {useImmer} from "use-immer";

import {
    Checkbox, Paper, Snackbar, Box,
    Grid, Toolbar, Typography, DialogTitle, DialogContent
} from "@mui/material";

import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import AddIcon from "@mui/icons-material/Add";
import CodeIcon from "@mui/icons-material/Code";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CreateIcon from "@mui/icons-material/Create";
import ListIcon from "@mui/icons-material/List";

import Dialog from "./mydialog";

import LayoutEditor from "./layouteditor";
import AttributeEditor from "./attributeeditor";
import Details from "./attributeeditor/details";
import SourceEditor from "./sourceeditor";

import ToolbarEntry from "./attributeeditor/toolbarentry";

import {ContainersContext} from "./containers";
import {CurrentContainerContext} from "./containers";

import {useLabel, useSaveLabel, useSetLabelId, useLabelId}  from "./labelprovider";
import {DataStructureProvider, useDataStructure} from "./datastructure";
import {useLabelEditorCommands} from "./commandsprovider";
import useConfigValue from "./configprovider";

import ErrorBoundary from "./errorboundary";

import {OldToNew, NewToOld} from "./conversion";

import AlignmentEditor from "./alignmenteditor";
import FormEditorDialog from "./formeditor";
import FieldListDialog from "./fieldlist";

import MyTooltip from "./attributeeditor/mytooltip";

import {temporaryUploadDesign, getInputForm, getDesign, saveDesign} from "./server";

import {useDesignListRefresh} from "./designlistprovider";

const MAX_UNDO_COUNT = 20;
const MAX_REDO_COUNT = 20;

const LabelEditor = ({parms}) => {
     const setLabelId = useSetLabelId();

     if (parms && parms != "/") {

         setLabelId(parms.substring(1));
        }

     return <Suspense fallback={<div>Loading...</div>}>
                <LabelEditorInner />
            </Suspense>;
    }

const LabelEditorInner = () => {
    const {t} = useTranslation();

    const layoutEditorRef = useRef(null);

    const portalRef = useRef(null);

    const [source, setSource] = useState(false);
    const [selected, setSelected] = useState(null);
    const  [depSelected, setDepSelected] = useImmer([]);

    const [label, setLabel] = useLabel();
    const saveLabel = useSaveLabel();

    const [nextPos, setNextPos] = useState(400);
    const [undo, setUndo] = useState([]);
    const [redo, setRedo] = useState([]);
    const [msg, setMsg] = useState("");
    const [sourceValid, setSourceValid] = useState(true);
    const [labelAttributesOpen, setLabelAttributesOpen] = useState(false);
    const [wizardOpen, setWizardOpen] = useState(false);
    const [formEditorOpen, setFormEditorOpen] = useState(false);
    const [formEditorValue, setFormEditorValue] = useState(null);
    const [labelCreateOpen, setLabelCreateOpen] = useState(false);
    const [fieldListOpen, setFieldListOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [labelId, setLabelId]  = useLabelId();

    const config = useConfigValue();

    const handleSelect = (fieldId, shift) => {

        if (shift) {
            if (selected == null) {
                setSelected(fieldId);
                } else {
                var newDepSelected = structuredClone(depSelected);
                if (newDepSelected.includes(fieldId)) {
                    newDepSelected = newDepSelected.filter(x => x != fieldId);
                    } else {
                    newDepSelected.push(fieldId);
                    }
                setDepSelected(newDepSelected);
                }
            } else {
             layoutEditorRef.current.onEndSelection();
            setSelected(fieldId);
            setDepSelected([]);
            }
        setWizardOpen(false);
        setFieldListOpen(false);
        }

    const handleSelectFieldEditor = (fieldId) => {
        handleSelect(fieldId, 0);
        setDetailsOpen(true);
        }
    const handleAddField = () => {

        //if (source) {
        //    var newLabel = sourceEditorRef.current.getSource();
        //    if (newLabel) {
        //        handleUpdate(newLabel);
        //        } else {
        //        setMsg(t("Invalid JSON"));
        //        return;
        //        }
        //    } else {
        //    var newLabel = structuredClone(label);
        //    }
        var newLabel = structuredClone(label);
        pushUndo(newLabel);

        newLabel = [...newLabel,
            {
                type: "field",
                position: {
                    "top": nextPos % 100,
                    "left": nextPos % 100,
                    "width": 10,
                    "height": 10,
                    },
                "form": {
                    "direction": "0",
                    "markupUsed": false,
                    "minFontSize": "1",
                    "vision_control": "Ignore",
                    "description": "",
                    "min": "",
                    "color": "000000",
                    "max": "",
                    "align": "W",
                    "fontSize": "60",
                    "font": "Arial",
                    "data": {
                        "xPath": "",
                        "dataScript": "",
                        "prompt": "",
                        "min": "",
                        "dataMapping": false,
                        "value": "",
                        "sample": "",
                        "textmapping": [],
                        "fieldType": "TEXT",
                        "max": "",
                        "dataScriptSelect": false,
                        "type": "dynamic",
                        "dynamic": false
                    },
                    "underline": false,
                    "name": t("Field {{count}}", {count: label.length})
                }
            }
        ];

        setLabel(newLabel);
        setNextPos(nextPos + 100);
        //setNextFieldSelect(newLabel.length - 1);
        layoutEditorRef.current.onSelect(newLabel.length - 1, 0);
        setWizardOpen(true);
        }

    const handleCloseWizard = () => {
        setWizardOpen(false);
        }

    const handleStore = (record) => {

        console.log("handleStore", record);

        pushUndo(label);
        var newLabel = structuredClone(label);
        newLabel[selected] = record;

        setLabel(newLabel);
        }

    const handleChange0 = (key, value, userData, withUndo=true) => {
        if (withUndo) {
            pushUndo(label);
            }
        var newLabel = structuredClone(label);
        newLabel[0].form = value;

        setLabel(newLabel);
        }

    const handleUpdate = (newLabel) => {

        pushUndo(label);

        setLabel(newLabel);
        }

    const handleDelete = () => {
        if (selected > 0) {
            pushUndo(label);
            var newLabel = structuredClone(label);
            newLabel.splice(selected, 1);
            setLabel(newLabel);
            setSelected(null);
            setDepSelected([]);
            setWizardOpen(false);
            }
        }

    const handleBack = () => {
        if (selected != 0) {

            pushUndo(label);
            var newLabel = structuredClone(label);

            var curField = newLabel[selected];
            newLabel.splice(selected, 1);
            var [firstField, ...rest] = newLabel;
            newLabel = [firstField, curField, ...rest];
            setLabel(newLabel);
            }
        setWizardOpen(false);
        }

    const handleUndo = () => {

        var newUndo = structuredClone(undo);

        var last = newUndo.pop();
        var newRedo = redo.slice(0, MAX_REDO_COUNT);
        newRedo.push(structuredClone(label));
        setLabel(last);
        setUndo(newUndo);
        setRedo(newRedo);
        setSourceValid(true);
        setWizardOpen(false);
        }

    const handleRedo = () => {
        var newRedo = structuredClone(redo);

        var last = newRedo.pop();
        var newUndo = undo.slice(0, MAX_UNDO_COUNT);
        newUndo.push(label);
        setLabel(last);
        setUndo(newUndo);
        setRedo(newRedo);
        setSourceValid(true);
        setWizardOpen(false);
        }

    const handleEndSelection = () => {
        layoutEditorRef.current.onEndSelection();
        setWizardOpen(false);
        }

    const handleSource = (event) => {
        if (sourceValid) {
            setSource(!source);
            setMsg("");
            }
        setWizardOpen(false);
        }

    const handleSourceChange = (newLabel) => {
        if (newLabel) {
            setMsg("");
            setSourceValid(true);
            handleUpdate(newLabel);
            } else {
            setMsg(t("Invalid JSON"));
            setSourceValid(false);
            }
        }

    const handleLabelAttributes = () => {
        setSource(false);
        setWizardOpen(false);
        setLabelAttributesOpen(true);
        }

    const handleLabelAttributesClose = () => {
        setLabelAttributesOpen(false);
        }

    const pushUndo = (saveLabel) => {
        var newUndo = undo.slice(0, MAX_UNDO_COUNT);
        newUndo.push(structuredClone(saveLabel));
        setUndo(newUndo);
        setRedo([]);
        }

    const handleRun = async () => {

        try {
            var data = await temporaryUploadDesign(NewToOld(label));
            console.log(data);
            data = await getInputForm(data.url);
            console.log(data);
            setFormEditorValue(data);
            setFormEditorOpen(true);
        } catch(error) {
            console.error("Error:", error);
            }
        }

    const handleSaveAs = (labelId=null) => {
        saveLabel(true);
        }
    const handleSave = async () => {
        await saveLabel(false);

        if (config.continueUrl) {
            if (config.continueUrl == "**close**") {
                window.open("", "_self").close();
                } else {
                window.location = config.continueUrl;
                }
            }
        }

    const handleCreate = () => {

        setLabelId(null);

        var emptyData = {
                    "value": "",
                    "type": "static",
				    "prompt": "",
				    "xPath": "",
				    "sample": "",
				    "fieldType": "TEXT",
				    "min": "",
				    "max": "",
				    "textmapping": [],
				    "dataScript": "",
				    "dataMapping": "NO",
				    "dataScriptSelect": "NO"
			};


        var template = [{
            type: "#Label",
            position: {
                top: 0,
                left: 0,
                width: 100,
                height: 100
            },
            form: {
                "label_page_format": "LandscapeA4",
                "label_page_width": "842",
                "label_page_height": "595",
                "label_resolution": "600",
                "label_count_x": "2",
                "label_count_y": "2",
                "label_left_border": "0",
                "label_right_border": "0",
                "label_top_border": "0",
                "label_bottom_border": "0",
                "label_count": emptyData,
                "label_lng": emptyData,
                "label_ToC": emptyData,
                "label_referenceGroup": emptyData,
                "label_reference": emptyData,
                "label_pull_url": "",
                }
            }];

        setLabel(template);
        setLabelCreateOpen(true);
        }

    const handleCreateClose = () => {
        setLabelCreateOpen(false);
        }

    const handleFieldList = () => {
        setFieldListOpen(true);
        }

    const handleFieldListClose = () => {
        setFieldListOpen(false);
        }

    const handleDetailsOpen = () => {
        setDetailsOpen(true);
        }

    const handleDetailsClose = () => {
        setDetailsOpen(false);
        }

    const handleMoveField = (movingNode, overNode) => {
        console.log("handleMoveField", movingNode, overNode);
        pushUndo(label);
        handleEndSelection()
        if (movingNode != overNode) {

            var movingRecord = label[movingNode];
            var without = label.filter((value, index) => index !== movingNode);
            if (movingNode < overNode) {
                var newLabel = without.slice(0, overNode - 1).concat([movingRecord]).concat(without.slice(overNode - 1));

                } else {
                var newLabel = without.slice(0, overNode).concat([movingRecord]).concat(without.slice(overNode));
                }
            setSelected(null);
            setDepSelected([]);
            setLabel(newLabel);
            }
        }

    var attributeEditor = [];

    if (selected != null) {
        if (depSelected.length == 0) {

            var containers = label.filter((value) => { return value.type ==  "container"});

            var containerOptions = containers.map(( value, index) => {
                return [value.form.guid, value.form.name];
                });

            var selectedField = label[selected];
            //console.log("LabelEditor", selectedField);

            attributeEditor = <ContainersContext.Provider value={containerOptions}>
                                <AttributeEditor key={ selected}
                                                 record={selectedField}
                                                 onStore={handleStore}
                                                 onDelete={handleDelete}
                                                 onBack={handleBack}
                                                 onEndSelection={handleEndSelection}
                                                 onCloseWizard={handleCloseWizard}
                                                 portal={portalRef}
                                                 wizardOpen={wizardOpen}
                                                 detailsOpen={detailsOpen}
                                                 onDetailsOpen={handleDetailsOpen}
                                                 onDetailsClose={handleDetailsClose}/>
                              </ContainersContext.Provider>;

            } else {
            attributeEditor = <>
                                <AlignmentEditor selected={selected}
                                                 depSelected={depSelected}
                                                 label={label}
                                                 onUpdate={handleUpdate}
                                                 portal={portalRef}
                                                 detailsOpen={detailsOpen}
                                                 onDetailsOpen={handleDetailsOpen}
                                                 onDetailsClose={handleDetailsClose}/>
                                <Toolbar>
                                    <Typography variant={"h6"} style={{flexGrow:1}} color="inherit">
                                        {t("Field Alignment")}
                                    </Typography>
                                </Toolbar>
                                <Typography>{t("Number of fields selected: {{number}}", {number: depSelected.length+1})}</Typography>
                            </>;
            }
        }

    if (label == null || label.length == 0) {
        return <div>{t("Loading")}</div>;
        }

    return <>
                <Box height={10} />
                <Toolbar ref={portalRef} sx={{maxHeight: "90px", minHeight: "90px", height: "90px"}}>
                    <ToolbarEntry name={t("Run")} iconTrue={PlayArrowIcon} color={"green"} onChange={handleRun} disabled={!(label?.length > 0)}/>
                    <ToolbarEntry name={t("Save")} iconTrue={SaveIcon} color={"green"} onChange={handleSave} disabled={(!sourceValid) || (undo.length==0 && !config.continueUrl) || (labelId == null)}/>
                    {!config.continueUrl &&
                        <>
                            <ToolbarEntry name={t("Save Copy")} iconTrue={SaveAsIcon} color={"green"} onChange={handleSaveAs} disabled={!sourceValid || (!(label?.length > 0))}/>
                            <ToolbarEntry name={t("New")} iconTrue={CreateIcon} color={"green"} onChange={handleCreate} />
                        </>
                        }

                    <MyTooltip title={t("Source")}>
                        <Checkbox onClick={handleSource}
                                  icon={<CodeIcon sx={{color:"red"}} />}
                                  checkedIcon={<CodeIcon />}
                                  value={source}
                                  sx={{margin: 1}}
                                  disabled={!sourceValid}>{t("Source")}</Checkbox>

                    </MyTooltip>

                    <ToolbarEntry name={t("Undo")} iconTrue={UndoIcon} color={"blue"} disabled={undo.length==0} onChange={handleUndo}/>
                    <ToolbarEntry name={t("Redo")} iconTrue={RedoIcon} color={"blue"} disabled={redo.length==0} onChange={handleRedo}/>
                    <ToolbarEntry name={t("Label")} iconTrue={VideoLabelIcon} color={"blue"} onChange={handleLabelAttributes}/>
                    <ToolbarEntry name={t("Fields")} iconTrue={ListIcon}  color={"blue"} onChange={handleFieldList} />
                    <ToolbarEntry name={t("Add Field")} iconTrue={AddIcon} color={"blue"} onChange={handleAddField} disabled={(!sourceValid) || (!(label?.length > 0))}/>

                </Toolbar>
                <Box height={10} />
                  {!source &&
                        <Grid container spacing={2}>
                            <Grid item xs={11} sx={{backgroundColor: "darkgray"}}>
                                <ErrorBoundary>
                                    <LayoutEditor ref={layoutEditorRef} label={label} selected={selected} depSelected={depSelected}
                                                 onSelect={handleSelect} onUpdate={handleUpdate} />
                                </ErrorBoundary>
                            </Grid>
                            <Grid item xs={1} >
                                <ErrorBoundary>
                                    {attributeEditor}
                                </ErrorBoundary>
                            </Grid>
                        </Grid>
                        }
                    {source &&
                        <Paper>
                            <ErrorBoundary>
                                <SourceEditor label={label} onChange={handleSourceChange}/>
                                <Snackbar open={msg != ""} autoHideDuration={6000} message={msg} />
                            </ErrorBoundary>
                        </Paper>
                        }
            {labelAttributesOpen &&
                 <CurrentContainerContext.Provider value={null}>
                     <Details open={labelAttributesOpen} title={t("Label Attributes")} record={label[0]}
                              onClose={handleLabelAttributesClose} onChange={handleChange0} disabled={!sourceValid} withType={false}/>
                 </CurrentContainerContext.Provider>
                    }

                {labelCreateOpen &&
                     <CurrentContainerContext.Provider value={null}>
                         <Details open={labelCreateOpen} title={t("Create Label")} record={label[0]}
                              onClose={handleCreateClose} onChange={handleChange0}  withType={false} wizard />
                     </CurrentContainerContext.Provider>
                    }

            {formEditorOpen &&
                <div>
                <FormEditorDialog open={formEditorOpen} onClose={() => {setFormEditorOpen(false)}} vform={formEditorValue.elements} oauth={formEditorValue._oauth[0]}/>
                </div>
              }

            {fieldListOpen &&
                <FieldListDialog open={fieldListOpen} label={label} selected={selected} depSelected={depSelected} onMoveField={handleMoveField} onSelect={handleSelectFieldEditor} onClose={handleFieldListClose} />
                }
        </>;
    }

const UpdateDataStructure = ({labelHeader, onUpdate}) => {
    const dataStructure = useDataStructure();

    useEffect(() =>{
        if (labelHeader.form?.label_data_format != dataStructure.formatName) {
            onUpdate("label_data_format", dataStructure.formatName, null, false);
            }

        }, [dataStructure, labelHeader]);
    }

export default LabelEditor;
