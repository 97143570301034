import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const WidthIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M280-320 120-480l160-160 57 56-64 64h414l-63-64 56-56 160 160-160 160-56-56 63-64H273l63 64-56 56Z" />

    </SvgIcon>
    );
  };

export const VerticalDistributeIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M80-80v-80h800v80H80Zm200-340v-120h400v120H280ZM80-800v-80h800v80H80Z" />

    </SvgIcon>
    );
  };

export const HorizontalDistributeIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M80-80v-800h80v800H80Zm340-200v-400h120v400H420ZM800-80v-800h80v800h-80Z" />
    </SvgIcon>
    );
  };

export const AlignStretchIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M280-560v-240H80v-80h800v80H680v240H280ZM80-80v-80h200v-240h400v240h200v80H80Z" />
    </SvgIcon>
    );
  };

export const AlignJustifyStretchIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M800-80v-800h80v800h-80ZM80-80v-800h80v800H80Zm440-480v-120h200v120H520Zm-280 0v-120h200v120H240Zm280 280v-120h200v120H520Zm-280 0v-120h200v120H240Z" />
    </SvgIcon>
    );
  };
