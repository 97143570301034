import React, {useState} from "react";
import AceEditor from "react-ace";

const ScriptAttribute = ({mode, theme, value, keyName, userData=null, onChange}) => {

    const [curValue, setCurValue] = useState(value);

    const handleChange = (newValue) => {
        setCurValue(newValue)
        }

    const handleBlur = () => {
        onChange(keyName, curValue, userData);
        }

    return  <AceEditor mode={mode} theme={theme} value={curValue} onChange={handleChange} onBlur={handleBlur}/>;
    }

export default ScriptAttribute;
