import React from "react";

import {atom, useAtom, useAtomValue, useSetAtom} from "jotai";

var config = {};

if (window.parent == window) {

    try {
        config = JSON.parse(decodeURIComponent(document.location.hash.substring(1)));
        } catch (e) {
        console.log(e);
        }
    }

export const configAtom = atom(config);
export const useConfig = () => {
    return useAtom(configAtom);
    }

export const useConfigValue = () => {

    return useAtomValue(configAtom);
    }

export const useSetConfig= () => {
    return useSetAtom(configAtom);
    }

export default useConfigValue;
