import dateModule from "date";

const LocalizeIso = (utc, dateTimeFormat) => {
		if (utc==undefined) {
			return("");
			}

		if (utc.length < 19) {
			return("");
			}

		var comp=utc.split("-").join(" ").split(":").join(" ").split(" ");
		var d=new Date(comp[0], comp[1]-1, comp[2], comp[3], comp[4], comp[5]);
		d.setUTCFullYear(comp[0]);
		d.setUTCMonth(comp[1]-1);
		d.setUTCDate(comp[2]);
		d.setUTCHours(comp[3]);
		d.setUTCMinutes(comp[4]);
		d.setUTCSeconds(comp[5]);
		d.setUTCMilliseconds(0);

		var ret = d.toString(dateTimeFormat);

	return (ret);
	}

export default LocalizeIso;
