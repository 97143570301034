import React, {useState} from "react";

import {Line, Rect} from "react-konva";

const LineField = ({width, height, form}) => {

    if (width > height) {
        var x0 = 0;
        var y0 = height/2;
        var x1 = width;
        var y1 = height/2;
        } else {
        var x0 = width/2;
        var y0 = 0;
        var x1 = width/2;
        var y1 = height;
        }

    var stroke = Number(form.stroke) / 10;
    var strokeColor = "#"+form.color;

    return <>
        <Rect scaleX={1} scaleY={1} x={0} y={0}  width={width} height={height}  fill={"lightgray"} opacity={0.8} />
        <Line scaleX={1} scaleY={1} x={0} y={0}  width={width} height={height} points={[x0, y0, x1, y1]} stroke={strokeColor} strokeWidth={stroke} opacity={0.8} />
        </>;
    }

export default LineField;
