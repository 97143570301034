import React, {useState, useEffect} from "react";

import {Button, DialogTitle, DialogContent, DialogActions} from "@mui/material";

import {useTranslation} from "react-i18next";

import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

import Dialog from "./mydialog";

import {useLabelEditorCommands} from "./commandsprovider";

import "./fieldlist.css";

const FieldList = ({label, selected, depSelected=[], onSelect, onMoveField}) => {
    const {t} = useTranslation();
    const rules = useLabelEditorCommands();
    const [gridApi, setGridApi] = useState(null);

    const handleRowDragEnd = (event) => {
        console.log("handleRowDragEnd", event);
        var movingNode = event.node.data.orgIndex;

        var overNode = event.overNode.data.orgIndex;
        onMoveField(movingNode, overNode);
        }

    const renderType = (cell) => {
        return <span>{rules[cell.value].nls}</span>;
        }
    const renderName = (cell) => {
        return <span>{cell.data.form.name}</span>;
        }

    const renderDescription = (cell) => {
        return <span>{cell.data.form.description}</span>;
        }

    const renderAction = (cell) => {
        const handleEdit = () => {
            onSelect(cell.data.orgIndex);

            }
        return <Button variant={"contained"} onClick={handleEdit}>{t("Open")}</Button>;
        }

    const getRowStyle = (params) => {
        if (params.node.data.status == 1) {
            return {background: 'red'};
            } else if (params.node.data.status == 2) {
            return {background: 'green'};
            }
        //return {background: 'white'};
        }

    const columnDefs = [
        {field: "type", headerName: t("Type"), cellRenderer: renderType},
        {field: "type", headerName: t("Name"), cellRenderer: renderName},
        {field: "type", headerName: t("Description"), cellRenderer: renderDescription},
        {field: "type", headerName: t("Action"), cellRenderer: renderAction}
        ];

    const rowClassRules = {
        "labelEditorSelected": (params) => params.data.status == 1,
        "labelEditorDepSelected": (params) => params.data.status == 2,
        "labelEditorUnselected": (params) => params.data.status == 0
    }

    const gridOptions = {
        //getRowStyle: getRowStyle,
        rowClassRules: rowClassRules
        }

    useEffect(() => {
        setGridApi(gridOptions.api);
        }, [gridOptions]);

    var rowData = label.filter((field, index) => index > 0).map((field, index) => {
        field.orgIndex = index + 1;

        if (field.orgIndex == selected) {
            field.status = 1;
            } else if (depSelected.includes(field.orgIndex)) {
            field.status = 2;
            } else {
            field.status = 0;
            }
        return field;
        });

    return <div className={"ag-theme-material"} style={{height: "80vh", width: "50vw"}}>
                <AgGridReact gridOptions={gridOptions} columnDefs={columnDefs} rowData={rowData.reverse()} rowDragEntireRow={true} animateRows onRowDragEnd={handleRowDragEnd}/>
            </div>;
    }

const FieldListDialog = ({open, onClose, ...props}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        onClose();
        }

    return <Dialog open={open} onClose={handleClose} maxWidth={"xl"}>
                <DialogTitle>{t("Field List")}</DialogTitle>
                <DialogContent>
                    <FieldList {...props} />
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>{t("Close")}</Button>
                </DialogActions>
            </Dialog>;
    }

export default FieldListDialog;
