import React, {useEffect} from "react";

import TextAttribute from "./textattribute";
import ColorAttribute from "./colorattribute";
import DateAttribute from "./dateattribute";

const TextElement = ({value, rule, keyName, onChange}) => {

    var width = undefined;

     useEffect(() => {
        var newValue = value ?? rule.value;
        if (value != newValue) {

            onChange(keyName, newValue, rule);
           }

        }, [keyName, rule]);

    if (rule.datepicker) {
        return <DateAttribute name={rule.caption} value={value} keyName={keyName} dateFormat={rule.datepicker.dateFormat} userData={rule} minDate={rule.datepicker.minDate} maxDate={rule.datepicker.maxDate} onChange={onChange}  />;
        }

    if (rule.class == "color") {
        return <ColorAttribute name={rule.caption} value={value} keyName={keyName} userData={rule} onChange={onChange}  />;
        }

    return <TextAttribute name={rule.caption} value={value} keyName={keyName} width={width} readOnly={rule.readonly=="true"} style={rule.style} userData={rule} onChange={onChange}  />;
    }

export default TextElement;
