import React from "react";
import {createRoot} from "react-dom/client";

import App from "./app";

var url = new URL("/esbuild", import.meta.url);

new EventSource(url).addEventListener('change', () => location.reload())

createRoot(document.querySelector("#app")).render(<App />);
