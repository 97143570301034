const updateLabelField = (oldRecord, newRecord,userData=null) => {

    //console.log(userData, oldRecord, newRecord);

    if (userData) {
        if  (userData.pageSize) {
            newRecord = structuredClone(newRecord);
            var pageSizeParm = userData.pageSize;
            var newPageSize = newRecord[userData.name];
            var pageRecord = pageSizeParm.formatTab[newPageSize];
            if (pageRecord) {
                if (pageRecord.x) {
                    newRecord[pageSizeParm.pageWidth] = pageRecord.x;
                   }
                if (pageRecord.y) {
                    newRecord[pageSizeParm.pageHeight] = pageRecord.y;
                    }
                if (pageRecord.resolution) {
                    newRecord[pageSizeParm.pageResolution] = pageRecord.resolution;
                    }
                } else {
                var oldPageSize = oldRecord[userData.name];
                if (pageSizeParm.conversion[oldPageSize]== undefined) {
                    oldPageSize = "pt";
                    }
                if (!pageSizeParm.conversion[newPageSize]== undefined) {
                    newPageSize = "pt";
                    }

                if (oldPageSize != newPageSize) {
                    var conversionFactorNew = pageSizeParm.conversion[newPageSize];
                    var conversionFactorOld = pageSizeParm.conversion[oldPageSize];
                    var conversionFactor = conversionFactorOld / conversionFactorNew ;

                    pageSizeParm.convert.forEach((fieldName) => {
                        newRecord[fieldName] = newRecord[fieldName] * conversionFactor;
                        });
                    }
                }
            }
        }

    return newRecord;
    }

export const overwriteRule =  (rule, value) => {
    if (["label_page_width", "label_page_height"].includes(rule.name)) {
        if (["mm", "cm", "in", "pt"].includes(value.label_page_format)) {
            rule["readonly"] = "false";
            } else {
            rule["readonly"] = "true";
            }
        }

    if (rule.name == "label_data_format") {
        rule.name = "label_data_format_dummy"
        }

    return rule;
    }

export default updateLabelField;
