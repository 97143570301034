import React from "react";

import {DformViewer} from "./dform";

const FieldsetElement = ({value, rule, preProc=null, onChange}) => {
     return <div style={{paddingTop: 20, paddingRight: 10}}>

                <fieldset style={{paddingTop: 10}}>
                    <legend style={{fontFamily: "Roboto"}}>{rule.caption}</legend>
                    <DformViewer value={value}
                           rules={rule.elements}
                           onChange={onChange}
                           preProc={preProc}
                           span={true}/>
                </fieldset>
        </div>;
    }

export default FieldsetElement;
