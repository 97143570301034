import React from "react";
import {Rect} from "react-konva";

const FrameField = ({width, height, type}) => {
    if (type == "#Label") {
        var color = "white";
        var opacity = 0.9;
        } else {
        var color = "lightgray";
        var opacity = 0.8;
        }

    return <Rect scaleX={1} scaleY={1} x={0} y={0}  width={width} height={height} opacity={opacity} fill={color} />;
    }

export default FrameField;
