import React, {useState} from "react";

import AceEditor from "react-ace";
import "brace/mode/python";
import "brace/mode/json";
import "brace/theme/github";

const  SourceEditor = ({label, onChange}) => {

    const [value, setValue] = useState(JSON.stringify(label, null, 4));
    const [curLabel, setCurLabel] = useState(label);
    const handleChange = (newValue) => {
        setValue(newValue);
        }

    const handleBlur = () => {
        try {
            onChange(JSON.parse(value));
            } catch (e) {
            onChange(null);
            }
        }

    return <AceEditor width={"90vw"} height={"90vh"} mode={"json"} theme={"github"} name={"source"} value={value} onChange={handleChange} onBlur={handleBlur}/>;
    }

export default SourceEditor;
