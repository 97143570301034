import React from "react";
import {FormControl, TextField, Autocomplete, } from "@mui/material";
import parse from "style-to-js";

import MyTooltip from "./mytooltip";

const TextAttribute = ({keyName, name, value, options=null, multi=false, width=300,
                           valid=true, error="", variant="form", style="", readOnly=false,
                            userData=null, tooltip=null,
                           onChange, onBlur, ...parms}) => {

    const handleChange = (event, value, reason) => {
        onChange(keyName, event.target.value, userData);
        }

    const handleInputChange = (event, newValue, reason) => {
        onChange(keyName, newValue, userData);
        }

    if (valid) {
        var backgroundColor = "white";
        } else {
        var backgroundColor = "yellow";
        }

    if (error) {
        var errorSet = true
        } else {
        var errorSet = false;
        }

    var sx = {width: width, padding: 1, backgroundColor: backgroundColor};
    var sx = {...sx, ...parse(style)};

    if (sx.textAlign == "right") {
        sx['& .MuiInputBase-input'] = {
          textAlign: 'right',
          flip: false,
          };
        }

    if (variant == "toolbar") {
        return <MyTooltip title={name}>
                    <TextField label={error || name}
                               value={value ?? ""}
                               onChange={handleChange}
                               onBlur={onBlur}
                               error={errorSet}
                               disabled={readOnly}
                               sx={sx}/>
                </MyTooltip>;
        }
    return <FormControl>
                <MyTooltip title={tooltip}>

                    {options == null &&
                            <TextField label={error || name}
                                   value={value ?? ""}
                                   multiline={multi}
                                   onChange={handleChange}
                                   onBlur={onBlur}
                                   error={errorSet}
                                   disabled={readOnly}
                                   sx={sx}
                                    />

                    }
                    {options != null &&
                        <Autocomplete label={name}
                                  disablePortal={true}
                                  openOnFocus={true}
                                  freeSolo={true}
                                  id={keyName + "Select"}
                                  value={value ?? ""}
                                  options={options}
                                  sx={{width: width, padding:1}}
                                  disabled={readOnly}
                                  onInputChange={handleInputChange}
                                  renderInput={(parms) => <TextField {...parms} label={name} multiline={multi}  />}/>
                    }
                </MyTooltip>
            </FormControl>;
    }

export default TextAttribute;

