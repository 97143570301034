import React, {useMemo} from "react";
import {Group, Text} from "react-konva";
import EditorSt from "./editorst";
import TextField from "./textfield";

import numeral from "numeral";

const formats = {
    1: "1.230,56",
    2: "$ 1.230,56",
    3: "1.230,56 $",
    4: "1230,56",
    5: "$ 1230,56",
    6: "1230,56 $",
    7: "1,230.56",
    8: "$ 1,230.56",
    9: "1,230.56 $",
    10: "1230.56",
    11: "$ 1230.56",
    12: "1230.56 $"
    };


const PriceField = ({form,  ...rest}) => {
    const curValue = useMemo(() => form.data.sample || form.data.value || form.data.prompt, [form]);

    var wrkValue = numeral(curValue.replace(form.decimalPoint, "."));

    if (["1", "2", "3", "7", "8", "9"].includes(form.format)) {
        var format = "1,230";
        } else {
        var format = "1230";
        }

    if (form.dashFull == "YES" && wrkValue < 1) {
        format = "1";
        }

    if (form.dashPartial == "NO" ||  wrkValue % 1 != 0) {
        format += ".";
        format += "00000000".substring(0, Number(form.precision));
        }


    var text = wrkValue.format(format);

    if (form.dashPartial == "YES" &&  wrkValue % 1 == 0) {
        text += "-";
        }

    if (form.dashFull == "YES" &&  wrkValue < 1) {
        text = "-" + text;

        }

    if (["1", "2", "3", "4", "5", "6"].includes(form.format)) {
        text = text.replace(",", "_");
        text = text.replace(".", ",");
        text = text.replace("_", ".")
        }


    if (["2", "5", "8", "11"].includes(form.format)) {
        text = form.currency + " " + text;
        }

    if (["3", "6", "9", "12"].includes(form.format)) {
        text += " " + form.currency ;
        }

    if (!form.printDecimalPoint) {
        if (Number(form.format) > 6) {
            text = text.replace(".", "");
            } else {
            text = text.replace(",", "");
            }
        }


    return <TextField {...rest} form={form} showValue={text} />;
    }

const PriceViewer = React.forwardRef(({text1, text2, ...rest}, ref) => {
    return <Group ref={ref} width={100}>
            <Text {...rest} text={text1} width={70}/>
            <Text {...rest} text={text2} width={30}/>
        </Group>
    });


export default PriceField;
