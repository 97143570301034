import React from "react";
import {Typography} from "@mui/material";

import {useTranslation} from "react-i18next";

const CustomTabPanel = ({value, index, children}) => {
    const {t} = useTranslation();

    if (value == index) {
        if (children == null) {
            children = <Typography>{index} {t("Panel")}</Typography>;
            }
        return children;
        } else {
        return null;
        }
    }

export default CustomTabPanel;
