import React, {Component} from "react";
import {Rect} from "react-konva";

const BoxField = ({width, height, form}) => {

    if (Number(form.stroke) > 0) {
        var fill = undefined;
        var stroke = Number(form.stroke) / 10;
        var strokeColor = "#"+form.color;
        } else {
        var fill = "#"+form.color;
        var stroke = 0;
        var strokeColor = fill;
        }

    return <Rect scaleX={1} scaleY={1} x={0} y={0}  width={width} height={height} fill={fill} strokeWidth={stroke} stroke={strokeColor} opacity={0.8} />;
    }

export default BoxField;
