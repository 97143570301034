import cookie from "cookie";
import {decode} from "universal-base64url";

import {NewToOld} from "./conversion";

const serverRequest =  async (path, parms={}, method={}) => {
    const origin =  document.location.origin;
    var urlSearchParams = new URLSearchParams(parms);
    var url = new URL(path, origin);
    url.search = urlSearchParams.toString();
    var response = await fetch(url, method)
    if (response.headers.get("Content-Type").split(";")[0] == "application/json") {
        return response.json();
        } else {
        return response.text();
        }
    }

const buildUrl = (path, parms) => {
    const origin = document.location.origin;
    var urlSearchParams = new URLSearchParams(parms);
    var url = new URL(path, origin);
    url.search = urlSearchParams.toString();
    return url.toString();
    }

export const getLoginUrl = () => {
    return buildUrl("/login", {"continue": "/signalLogin"});
    }

export const getLogoutUrl = () => {
    return buildUrl("/logout", {"continue": "/logoutComplete.html"});
    }

export const getUserInfo = () => {
    try {
        var data = cookie.parse(document.cookie);
        var userInfo = decode(data["RSJ_ACCESS"]);
        userInfo = JSON.parse(userInfo);
        var [token, emailHash, nickName, license, email,  userId] = userInfo;
        userInfo = {token, emailHash, nickName, license, email, userId};
        return userInfo;
        } catch (e) {
        }

    return null;
    }

export const getLabelEditorCommands = async (language=null) => {
    if (language) {
        document.cookie = "LANGUAGE=" + language;
        }
    return serverRequest("/labeleditorcommands");
    }

export const getDesignList = async () => {
    return serverRequest("/designlistjson2", {deep: "true"});
    }

export const getDesign = async (id) => {
    var data = await serverRequest("/designjson", {id});
    return JSON.parse(data);
    }

export const temporaryUploadDesign = async (label) => {
    return serverRequest("/try", {}, {method: "POST", body: JSON.stringify(label), headers: {"Content-Type": "application/json"}});
    }

export const getInputForm = async (id) => {
    var wrk = id;

    if (typeof(id) == "string") {
        wrk = {id}
        }
    return serverRequest("/vformjson", wrk);
    }

export const getLabelUrl = (parms) => {
    var url = buildUrl("/produceLabel", parms);
    return url;
    }

export const getDataStructure = async (id) => {
    return serverRequest("/dataParser", {id});
    }

export const getJobList = async () => {
    return serverRequest("/joblist2");
    }

export const getResourceUrl = (id) => {
    return buildUrl("/designBlob/" + id);
    }

export const getFontUrl = (id) => {
    return buildUrl("/font/" + id);
    }

export const getUploadUrl = async (resourceType) => {
    var data = await serverRequest("/upload", {type:resourceType});
    return data.url;
    }

export const uploadFile = async ({file, fileName, resourceName, resourceDescription, resourceType}) => {
    var rsp = await getUploadUrl(resourceType);

    const formData = new FormData();
    formData.append("fileContent", file);
    formData.append("fileName", resourceName);
    formData.append("fileDescription", resourceDescription);
    formData.append("type", resourceType);

    var url = new URL(rsp, document.location.origin);

    rsp = await  fetch(url, {method: "POST", body: formData});
    var data = await  rsp.text();
    return data.split("'")[3];
    }

export const getDownloadUrl= (pdfId) => {
    return buildUrl( pdfId);
    }

export const getFontList = async () => {
    return serverRequest("/fontList");
    }

export const saveDesign = async (design, id=null) => {
    var options = {
        source: "ui6"
        };

    if (id) {
        options.id = id;
        }
    return serverRequest("/design", options, {method: "POST", body: JSON.stringify(design), headers: {"Content-Type": "application/json"}});
    }

export const getAvatarUrl = (avatarId) => {
    var url =  new URL("https://www.gravatar.com/avatar/" + avatarId + "?s=64&d=identicon&r=PG", document.location.origin);
    return url.toString();
    }

export const submitJob = async (format, data, jobParms, oauth) => {
    var formData = new FormData();
    formData.append("format", format);
    if (data) {
        formData.append("data", data);
        } else {
        formData.append("data", "");
        }

    formData.append("jobParms", JSON.stringify(jobParms));
    formData.append("oauth_token", oauth);
    return serverRequest("/submit", {}, {method: "POST", body: formData});
    }

export const updJob = async (jobId, newStatus) => {
    var options = {
        id: jobId,
        ...newStatus
        };
    return serverRequest("/updjob", options);
    }

export const getSseUrl = (channel) => {
    return buildUrl("/sse/" + channel);
    }

export const deleteResource = async (id) => {
    return serverRequest("/deletedesign", {id});
    }

export const packageResource = async (id) => {
    return serverRequest("/package", {id});
    }

export const getMaster = async (id) => {
    return serverRequest("/masterLabel", {master: id});
    }

export const storeMaster = async (parms) => {
    return serverRequest("/storeMaster", parms);
    }











